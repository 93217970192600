<template>
  <div>
    <br/>
    <br/>
    <CRow>
      <CCol 
        md="12" 
        lg="12" 
        sm="12" 
        xs="12"  
        class="d-flex justify-content-between">
        <button
          v-if="verificBead == false && this.box"
          @click="mtdOpenModalOpenBead"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-cash-register"></i> Abrir Caja
        </button>

        <!-- Botón para cerrar caja -->
        <button
          v-if="verificBead == true && this.box"
          @click="mtdCloseBead"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-cash-register"></i> Cerrar Caja
        </button>
        <button
          @click="mtdBackRol"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-hand-point-left"></i> Cambiar de rol
        </button>
      </CCol>
    </CRow>
    <br />
    <CRow class="mt-3">
      <CCol sm="6" lg="3">
        <CWidgetProgress
          :header="'S/. '+totalApertura"
          text="Apertura de Caja"
          color="gradient-info"
          inverse
        />
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetProgress
          :header="'S/. '+totalIngresos"
          text="Total de ingresos"
          color="gradient-warning"
          inverse
        />
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetProgress
          :header="'S/. '+totalCaja"
          text="Monto total en caja"
          color="gradient-success"
          inverse
        />
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetProgress
          :header="String(totalCitas)"
          text="Total de citas"
          color="gradient-primary"
          inverse
        />
      </CCol>
    </CRow>
    <!-- <div class="container mx-auto p-4"> -->
      <cModalOpenBead
        :title="modalOpenBead.title"
        :boo_modal="modalOpenBead.modal_form"
        @closeModalOpenBead="closeModalOpenBead"
        @save="handleSave"
      >
      </cModalOpenBead>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalOpenBead from "../caja/modals/cModalOpenBead.vue";

export default {
  name: "home-adminCompany",
  components: {
    cModalOpenBead,
  },
  data() {
    return {
      prefix: "beads",
      box: false,
      verificBead: false,
      modalOpenBead: {
        title: "Registrar monto de inicio",
        modal_form: false,
      },
      company: {
        logo: "",
      },
      totalApertura: "",
      totalIngresos: "",
      totalCaja: "",
      totalCitas: "",
      filter: {
        init: null,
        end: null,
      },
    };
  },
  created() {
    moment.locale("es");
    this.filter.end = moment().format("YYYY-MM-DD");
    this.filter.init = this.filter.end;
    this.showQuote();
    this.showBead();
    this.showCompany();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackRol: function () {
      this.$store.commit("mt_set_rol", null);
    },
    mtdCloseBead: function () {
      this.$emit("setView", "close_bead");
    },
    mtdOpenModalOpenBead() {
      this.modalOpenBead = {
        title: "Registrar monto de inicio",
        modal_form: true,
      };
    },
    closeModalOpenBead() {
      this.modalOpenBead = {
        modal_form: false,
      };
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // console.log(response);
          if (response.statusCode == 200) {
            this.verificBead = response.data ? true : false;
            this.bead = this.verificBead == true ? response.data : {};
            this.totalApertura = response.data.initial;
            this.totalIngresos = response.data.income;
            this.totalCaja = response.data.final;  
          }
        })
        .catch((errors) => {});
    },
    showCompany() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showCompany/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.company.logo = response.data.logo;
            this.box = response.data.box;
          }
        })
        .catch((errors) => {});
    },
    showQuote() {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/quotes",
        token: this.$store.getters.get__token,
        params: {
          init: this.filter.init,
          end: this.filter.end,
          campu_id: this.$store.getters.get_campu.value,
        },
      })
        .then((response) => {
          this.data = response.data.data;
          this.totalCitas = this.data.length;
        })
        .catch((errors) => {
          Swal.fire({
            title: errors,
            icon: "danger",
          });
        });
    },
    handleSave() {
      this.showBead();
      this.modalOpenBead = {
        modal_form: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 460px;
  padding: 10px;

  // Pantallas medianas
  @media (max-width: 768px) {
    height: 400px;
  }

  // pantallas pequeñas
  @media (max-width: 480px) {
    height: auto;
    padding: 5px;
    margin: 10px 0;
  }
}
.contenedor-img {
  width: 35%;
  height: 80%;
   // Pantallas medianas
   @media (max-width: 768px) {
    width: 50%;
  }

  // pantallas pequeñas
  @media (max-width: 480px) {
    width: 60%;
  }
}
.imagen {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 0px;
}
</style>
