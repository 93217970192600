<template>
  <div class="container mx-auto">
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <b><i class="fas fa-calendar"></i>&nbsp;Movimientos</b>
            <button type="button" class="btn btn-sm text-white" @click="returnHome" style="background: #926cdc">
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <!-- Filtro por fechas -->
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6" xxl="6">
                <CInput label="Desde" type="date" @input="mtdGetData" v-model="kardex.init" size="sm" />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6" xxl="6">
                <CInput label="Hasta" type="date" @input="mtdGetData" v-model="kardex.end" size="sm" />
              </CCol>
            </CRow>
            <CRow class="d-flex justify-content-end">
              <CCol xs="12" sm="12" md="12" lg="12" xxl="12" class="d-flex justify-content-end">
                <button type="button" class="btn btn-sm text-white" @click="exportPdf" style="background: #926cdc"
                  :disabled="!showTable">
                  <i class="fas fa-file-pdf"></i> Exportar Pdf
                </button>
              </CCol>
            </CRow>

            <!-- Fin Filtro por fechas -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
        <CCard v-if="showTable">
          <CCardBody class="p-1">
            <div class="kardex-title">
              KARDEX
            </div>
          </CCardBody>
          <CCardBody>
            <div id="content-timelinetable">
              <table id="tb-cxc" class="table table-bordered border-main align-middle table-responsive">
                <thead>
                  <tr class=" text-center my-auto" style="background: #4E4C4D; color: white; vertical-align: middle">
                    <th scope="col" colspan="1" rowspan="2" style="vertical-align: middle;">
                      FECHA
                    </th>
                    <th scope="col" colspan="1" rowspan="2" style="vertical-align: middle;">
                      TIPO DOC.
                    </th>
                    <th scope="col" colspan="1" rowspan="2" style="vertical-align: middle;white-space: nowrap; ">
                      SERIE Y N° DE DOC.
                    </th>
                    <th scope="col" colspan="1" rowspan="2" style="vertical-align: middle;">
                      TIPO OPE.
                    </th>
                    <th scope="col" colspan="3" style="vertical-align: middle;">
                      INGRESOS
                    </th>
                    <th scope="col" colspan="3" style="vertical-align: middle;">
                      SALIDAS
                    </th>
                    <th scope="col" colspan="3" style="vertical-align: middle;">SALDO</th>
                  </tr>
                  <tr class=" text-center" style="background: #4E4C4D; color: white ;vertical-align: middle">
                    <th scope="col" style="vertical-align: middle;">Cantidad</th>
                    <th scope="col" style="vertical-align: middle;">Costo Unit.</th>
                    <th scope="col" style="vertical-align: middle;">Costo Total</th>
                    <th scope="col" style="vertical-align: middle;">Cantidad</th>
                    <th scope="col" style="vertical-align: middle;">Costo Unit.</th>
                    <th scope="col" style="vertical-align: middle;">Costo Total</th>
                    <th scope="col" style="vertical-align: middle;">Cantidad</th>
                    <th scope="col" style="vertical-align: middle;">Costo Unit.</th>
                    <th scope="col" style="vertical-align: middle;">Costo Total</th>
                  </tr>
                </thead>

                <tbody class="custom-table text-center">
                  <tr class="text-center" style="
                      vertical-align: middle;
                      color: #900052;
                      background: #EBEDEF;
                    ">
                    <th scope="col" colspan="4" style="font-style: italic">
                      *** TOTALES
                    </th>
                    <th scope="col">
                      {{ totals.totalSumaCantidadEn }}
                    </th>
                    <th scope="col" colspan="2">
                      S/. {{ totals.totalSumaTotalEn }}
                    </th>
                    <th scope="col">
                      {{ totals.totalSumaCantidadSa }}
                    </th>
                    <th scope="col" colspan="2">
                      S/. {{ totals.totalSumaTotalSa }}
                    </th>
                    <th scope="col" colspan="3" style="font-style: italic"></th>
                  </tr>

                  <template v-for="(product, productId) in dataKardex">
                    <tr class="text-center  bg-white" :key="'product_' + productId">
                      <th scope="col" colspan="13">
                        {{ product[0].name }} ( {{ product[0].name_unidad }} )
                      </th>
                    </tr>

                    <tr v-for="(item, index) in product" :key="'item_' + productId + '_' + index">
                      <template v-if="item.type == 1">
                        <td>
                          {{ item.date | formatDate }}
                        </td>
                        <td>{{ item.documentCom }}</td>
                        <td>{{ item.serieCom }}</td>
                        <td style="cursor: pointer">
                          <span v-if="item.type == 1" class="badge bg-success text-white p-1">ENTRADA</span>
                          <span v-else-if="item.type == 2" class="badge bg-danger text-white p-1">SALIDA</span>
                        </td>
                        <td>{{ item.quantity }}</td>
                        <td>S/. {{ item.price_purchase }}</td>
                        <td>S/. {{ item.producto }}</td>
                        <td colspan="3">-</td>
                        <td>{{ item.stock_new }}</td>
                        <td>S/. {{ item.price_purchase }}</td>
                        <td>S/. {{ item.producto_saldo }}</td>
                      </template>
                      <template v-if="item.type == 2">
                        <td>
                          {{ item.date | formatDate }}
                        </td>
                        <td>{{ item.document }}</td>
                        <td>
                          {{ item.serie }}-{{ item.number }}
                        </td>
                        <td style="cursor: pointer">
                          <span v-if="item.type == 1" class="badge bg-success text-white p-1">ENTRADA</span>
                          <span v-else-if="item.type == 2" class="badge bg-danger text-white p-1">SALIDA</span>
                        </td>
                        <td colspan="3">-</td>
                        <td>{{ item.quantity }}</td>
                        <td>S/. {{ item.price_purchase }}</td>
                        <td>S/. {{ item.producto }}</td>
                        <td>{{ item.stock_new }}</td>
                        <td>S/. {{ item.price_purchase }}</td>
                        <td>S/. {{ item.producto_saldo }}</td>
                      </template>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { bus } from "../../../../main";
import moment from "moment";

export default {
  name: "kardex",
  components: {},
  data() {
    return {
      kardex: {
        init: null,
        end: null,
        campu: this.$store.getters.get_campu.value,
      },
      dataKardex: [],
      showTable: false,
      excelData: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    moment.locale("es");
    this.kardex.end =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.kardex.init = this.kardex.end;
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome() {
      this.$emit("returnHome");
    },
    mtdGetData: function () {
      this.post({
        url: this.$store.getters.get__url + "/Kardex/index",
        token: this.$store.getters.get__token,
        params: this.kardex,
      })
        .then((response) => {
          this.dataKardex = response.data.data;
          this.totals = response.data.totals;
          if (this.dataKardex.length == 0) {
            this.showTable = false;
            this.noRecordsMessage = "No se encontraron Resultados";
            Swal.fire({
              title: "Sin Registros",
              icon: "warning",
            });
          } else {
            this.showTable = true;
            this.noRecordsMessage = "";
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    exportPdf: function () {
      window.open(
        this.$store.getters.get__url +
        "/Kardex/viewpdf/" +
        this.kardex.campu + "/" +
        this.kardex.init + "/" +
        this.kardex.end,
        "_blank"
      );
    },
    exportExcel: function () {
      /*
      const datos = this.excelData;
      const fileName = 'KARDEX';
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
          data: datos,
          fileName: fileName,
          exportType: typeExport,
      });
      */
    },
    // applyDateFilter() {
    //   this.post({
    //     url: this.$store.getters.get__url + "/" + this.prefix + "/index",
    //     token: this.$store.getters.get__token,
    //     params: this.kardex,
    //   })
    //     .then((response) => {
    //       this.data = response.data.data;
    //       // this.filterData(startDate, endDate);
    //     })
    //     .catch((errors) => {
    //       bus.$emit("alert", {
    //         color: "danger",
    //         message: errors,
    //       });
    //     });
    // },
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatDateTime(dateTime) {
      return dateTime != null
        ? moment(dateTime).format("DD/MM/YYYY HH:mm")
        : "-";
    },
  },
};
</script>
<style scoped>

  .custom-table th,
  .custom-table td {
    font-size: 11px;
    color: black;
  }

  . {
    border: 1.5px solid black;
  }

  .bg-white {
    background: #eaf4f4 !important;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .kardex-title {
    background-color: #dd5a02;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px;
  }
</style>
