<template>
  <div>
    <br />
    <CRow>
      <CCol
        md="12"
        lg="12"
        sm="12"
        xs="12"
        class="d-flex justify-content-between"
      >
        <button
          v-if="verificBead == false && this.box"
          @click="mtdOpenModalOpenBead"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-cash-register"></i> Abrir Caja
        </button>

        <!-- Botón para cerrar caja -->
        <button
          v-if="verificBead == true && this.box"
          @click="mtdCloseBead"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-cash-register"></i> Cerrar Caja
        </button>
        <button
          @click="mtdBackCampus"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-hand-point-left"></i> Cambiar de sede
        </button>
      </CCol>
    </CRow>
    <br />
    <!-- aqui graficos de los ingresos y/o el estado de las canchitas -->
    <div class="container mx-auto p-4">
      <cModalOpenBead
        :title="modalOpenBead.title"
        :boo_modal="modalOpenBead.modal_form"
        @closeModalOpenBead="closeModalOpenBead"
        @save="handleSave"
      >
      </cModalOpenBead>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cModalOpenBead from '../caja/modals/cModalOpenBead.vue';

export default {
  name: "home-recepcionist",
  components: {
    cModalOpenBead,
  },
  data() {
    return {
      prefix: "beads",
      box: false,
      verificBead: false,
      modalOpenBead: {
        title: "Registrar monto de inicio",
        modal_form: false,
      },
      bead: {
        id: "",
        box_id: "",
        user_id: this.$store.getters.get__user_id,
        campu_id: this.$store.getters.get_campu.value,
        initial: "",
        final: "",
        expense: "",
        income: "",
        card: "",
        turned: "",
        transfer: "",
        retirement: "",
        status_open: "",
        status_close: "",
        deposit: "",
        authorize: "",
        state: "",
      },
    };
  },
  created() {
    this.showBead();
    this.showCompany();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdCloseBead: function () {
      this.$emit("setView", "close_bead");
    },
    mtdOpenModalOpenBead() {
      this.modalOpenBead = {
        title: "Registrar monto de inicio",
        modal_form: true,
      };
    },
    closeModalOpenBead() {
      this.modalOpenBead = {
        modal_form: false,
      };
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.verificBead = response.data ? true : false;
            this.bead = this.verificBead == true ? response.data : {};
          }
        })
        .catch((errors) => {});
    },
    showCompany() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/showCompany/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.box = response.data.box;
          }
        })
        .catch((errors) => {});
    },
    handleSave() {
      this.showBead();
      this.modalOpenBead = {
        modal_form: false,
      };
    },
  },
};
</script>  
<style lang="scss" scoped></style>