<template>
  <div>
    <CRow>
      <CCol
        :md="tableColSize"
        :lg="tableColSize"
        :xl="tableColSize"
        :xxl="tableColSize"
        xs="12"
        sm="12"
      >
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Productos"
          icon="fas fa-coins"
          btn_name="Producto"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_table_barcode="mtd_table_barcode"
          @mtd_table_price="mtd_table_price"
          @mtd_table_productUnit="mtd_table_productUnit"
          :buttonBack="true"
        />
      </CCol>
      <CCol
        v-for="table in barcodeTables"
        :key="table.id"
        md="5"
        lg="5"
        xl="5"
        xxl="5"
        xs="12"
        sm="12"
      >
        <button
          @click="removeBarcodeTable(table.id)"
          class="btn btn-dark mb-1 d-flex"
        >
          <i class="fas fa-eye"></i>
          <div class="ml-2">Ocultar</div>
        </button>
        <CTableWrapper
          :items="table.data"
          :fields="table.fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          :caption="`Lista de Códigos de Barras - ${table.name_product.toUpperCase()}`"
          icon="fas fa-barcode"
          btn_name="Código"
          @mtdBack="mtdBack"
          @show_modal="mtd_barcode"
          @action_delete="mtd_delete_barcode"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="false"
          :buttonDelete="true"
          :myButtons="[]"
          :buttonBack="false"
        />
      </CCol>

      <CCol
        v-for="table in priceTables"
        :key="table.id"
        md="5"
        lg="5"
        xl="5"
        xxl="5"
        xs="12"
        sm="12"
      >
        <button
          @click="removePriceTable(table.id)"
          class="btn btn-dark mb-1 d-flex"
        >
          <i class="fas fa-eye"></i>
          <div class="ml-2">Ocultar</div>
        </button>
        <CTableWrapper
          :items="table.data"
          :fields="table.fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          :caption="`Lista de Precios - ${table.name_product}`"
          icon="fas fa-dollar-sign"
          btn_name="Precios"
          @mtdBack="mtdBack"
          @show_modal="mtd_price"
          @action_edit="mtd_show_price"
          :size="'md'"
          :button_new="table.data.length == 0 ? true : false"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="false"
          :myButtons="[]"
          :buttonBack="false"
        />
      </CCol>

      <CCol
        v-for="table in productUnitTables"
        :key="table.id"
        md="5"
        lg="5"
        xl="5"
        xxl="5"
        xs="12"
        sm="12"
      >
        <button
          @click="removeProductUnitTable(table.id)"
          class="btn btn-dark mb-1 d-flex"
        >
          <i class="fas fa-eye"></i>
          <div class="ml-2">Ocultar</div>
        </button>
        <CTableWrapper
          :items="table.data"
          :fields="table.fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          :caption="`Lista de Producto x Unidad - ${table.name_product}`"
          icon="fas fa-hockey-puck"
          btn_name="Producto x Unidad"
          @mtdBack="mtdBack"
          @show_modal="mtd_productUnit"
          @action_edit="mtd_show_productUnit"
          :size="'md'"
          :button_new="true"
          :actions="false"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="false"
          :myButtons="[]"
          :buttonBack="false"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digite nombre"
            v-model="product.name"
          />
        </CCol>
        <CCol xs="12" sm="12" md="6" lg="6" xl="6">
          <CInput
            label="Stock Mínimo"
            type="number"
            :min="0"
            v-model="product.stock_min"
            @input="validationNumber()"
            @keydown="preventDecimal"
          />
        </CCol>
        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="product.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal codigo de barras -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button_barcode"
      :show.sync="modal.modal_form_barcode"
      @mtd_action="mtd_action_barcode"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Nombre"
            placeholder="Digete nombre"
            v-model="barcode.name"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal precios -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button_price"
      :show.sync="modal.modal_form_price"
      @mtd_action="mtd_action_price"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            label="Precio de Venta"
            type="number"
            placeholder="Digete precio de venta"
            v-model="price.sale_price"
            @change="validatePositive('sale_price')"
          />
        </CCol>
        <CCol sm="12">
          <CInput
            label="Precio de Compra"
            type="number"
            placeholder="Digete precio de compra"
            v-model="price.purchase_price"
            @change="validatePositive('purchase_price')"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal Producto x Unidad -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button_productUnit"
      :show.sync="modal.modal_form_productUnit"
      @mtd_action="mtd_action_productUnit"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Unidad"
            placeholder="Seleccione unidad"
            :options="data_units"
            :value.sync="productUnit.unit_id"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Producto'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import CSwitchVue from "../shared/switch/CSwitch.vue";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "products",
      fields,
      data: [],
      data_units: [],
      tableColSize: 12, // Valor inicial de la tabla
      myButtons: [
        {
          class: "btn-sm",
          color: "dark",
          tooltip: "Agregar Código de Barras",
          action: "mtd_table_barcode",
          icon: "fas fa-barcode",
        },
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar Precio",
          action: "mtd_table_price",
          icon: "fas fa-dollar-sign",
        },
        {
          class: "btn-sm",
          color: "dark",
          tooltip: "Unidad de producto",
          action: "mtd_table_productUnit",
          icon: "fas fa-hockey-puck",
        },
      ],
      modal: {
        action: "",
        title: "",
        modal_form: false,
        modal_form_barcode: false,
        modal_form_price: false,
        modal_form_productUnit: false,
      },
      product: {
        id: "",
        company_id: this.$store.getters.get_company.id,
        name: "",
        stock_min: 0,
        state: 0,
      },
      barcode: {
        product_id: "",
        name: "",
        state: 0,
      },
      price: {
        product_id: "",
        sale_price: 0,
        purchase_price: 0,
        state: 0,
      },
      productUnit: {
        product_id: "",
        unit_id: "",
        unit: {},
        state: 0,
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      barcodeTables: [],
      priceTables: [],
      productUnitTables: [],
      nextBarcodeTableId: 1, // Counter for unique table ID
      nextPriceTableId: 1, // Counter for unique table ID
      nextProductUnitTableId: 1, // Counter for unique table ID
    };
  },
  computed: {
    cpStatus: function () {
      if (this.product.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (this.product.name != "") return false;
      return true;
    },
    cp_button_barcode: function () {
      if (this.barcode.name != "") return false;
      return true;
    },
    cp_button_price: function () {
      if (this.price.sale_price != "") return false;
      return true;
    },
    cp_button_productUnit: function () {
      if (this.productUnit.unit_id != "") return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
    this.show_Unit();
  },
  methods: {
    ...mapActions(["get", "post"]),
    validationNumber(){
      const quantity = parseInt(event.target.value, 10);
      if (quantity < 1) {
        this.product.stock_min = 1;
      }
    },
    preventDecimal(event) {
      // Prevenir que se ingresen puntos decimales o comas
      if (event.key === "." || event.key === ",") {
        event.preventDefault();
      }
    },
    mtd_getdata: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.data;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Producto")
        : (this.modal.title = "Editar Producto");
      action == "store"
        ? (this.product = {
            id: "",
            company_id: this.$store.getters.get_company.id,
            name: "",
            state: "",
          })
        : (this.product = { ...this.product });
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.product,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Producto ya resgistrado",
              icon: "warning",
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedProduct = response.data.data;
              let index = this.data.findIndex(
                (product) => product.id === updatedProduct.id
              );
              if (index !== -1) {
                this.$set(this.data, index, updatedProduct);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.product = {
              id: "",
              company_id: this.$store.getters.get_company.id,
              name: "",
              state: 0,
            };

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.product = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          this.mtd_getdata();
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    changeSwitch: function (payload) {
      this.product.state = payload == true ? 1 : 0;
    },
    mtd_table_barcode: function (id) {
      if (this.priceTables.length != 0) {
        this.removePriceTable(this.priceTables[0].id);
      }
      if (this.productUnitTables.length != 0) {
        this.removeProductUnitTable(this.productUnitTables[0].id);
      }
      if (this.barcodeTables.length == 1) {
        this.removeBarcodeTable(this.barcodeTables[0].id);
      }

      this.tableColSize = 7;
      this.barcode.product_id = id.id;
      this.barcode.name = "";

      // Add a new barcode table
      if (this.nextBarcodeTableId == 1) {
        const newBarcodeTable = {
          id: this.nextBarcodeTableId,
          data: [], // You can fill this with actual barcode data as needed
          fields: [
            { key: "index", label: "Id", _style: "width:1%" },
            { key: "name", label: "Nombre", _style: "width:20%;" },
          ],
          name_product: id.name,
        };
        this.barcodeTables.push(newBarcodeTable);
        this.mtd_getdata_barcode(newBarcodeTable.id, id.id);
        this.nextBarcodeTableId += 1;
      }
    },
    removeBarcodeTable(tableId) {
      this.nextBarcodeTableId = 1;
      this.barcodeTables = this.barcodeTables.filter(
        (table) => table.id !== tableId
      );

      // Si no quedan más tablas de códigos de barras, restablece el tamaño de la columna principal
      if (this.barcodeTables.length === 0) {
        this.tableColSize = 12;
      }
    },
    mtd_barcode: function () {
      this.tableColSize = 7;
      this.modal.modal_form_barcode = true;
      this.modal.title = "Agregar Codigo de Barras";
      this.barcode.name = "";
    },

    mtd_action_barcode: function () {
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/barcodes/store",
        token: this.$store.getters.get__token,
        params: this.barcode,
      })
        .then((response) => {
          message = "REGISTRADO CORRECTAMENTE";
          this.mtd_getdata_barcode(
            this.barcodeTables[0].id,
            this.barcode.product_id
          );
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            modal_form_barcode: false,
            modal_form_price: false,
            modal_form_productUnit: false,
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_getdata_barcode: function (tableId, productId) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byBarcode/" +
          this.$store.getters.get_company.id +
          "/" +
          productId,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // this.data = response.data.data;
          const tableIndex = this.barcodeTables.findIndex(
            (table) => table.id === tableId
          );
          if (tableIndex !== -1) {
            this.$set(
              this.barcodeTables[tableIndex],
              "data",
              response.data.data
            );
            // Agregar un índice a cada elemento en la data
            let index = 1;
            this.barcodeTables[tableIndex].data.forEach((item) => {
              item.index = index;
              index++;
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete_barcode(item) {
      // Muestra una confirmación antes de eliminar
      Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminarlo",
      }).then((result) => {
        if (result.isConfirmed) {
          this.post({
            url: this.$store.getters.get__url + "/barcodes/destroy",
            token: this.$store.getters.get__token,
            params: {
              id: item.id,
            },
          })
            .then((response) => {
              if (response.data) {
                // Eliminar el barcode de la tabla localmente
                const tableIndex = this.barcodeTables.findIndex(
                  (table) => table.id == this.nextBarcodeTableId - 1
                ); // Asume que solo tienes un barcodeTable activo

                if (tableIndex !== -1) {
                  const barcodeIndex = this.barcodeTables[
                    tableIndex
                  ].data.findIndex((barcode) => barcode.id === item.id);
                  if (barcodeIndex !== -1) {
                    this.barcodeTables[tableIndex].data.splice(barcodeIndex, 1);
                  }
                }
                // this.barcodeTables[tableIndex].data = [];
                // this.mtd_getdata_barcode(this.barcodeTables[0].id, this.barcode.product_id);
                Swal.fire(
                  "¡Eliminado!",
                  "El código de barras ha sido eliminado.",
                  "success"
                );
              }
            })
            .catch((error) => {
              Swal.fire(
                "Error",
                "Ocurrió un error al eliminar el código de barras.",
                "error"
              );
            });
        }
      });
    },
    mtd_table_price: function (id) {
      if (this.barcodeTables.length != 0) {
        this.removeBarcodeTable(this.barcodeTables[0].id);
      }
      if (this.productUnitTables.length != 0) {
        this.removeProductUnitTable(this.productUnitTables[0].id);
      }
      if (this.priceTables.length == 1) {
        this.removePriceTable(this.priceTables[0].id);
      }

      this.tableColSize = 7;
      this.price.product_id = id.id;
      this.price.purchase_price = 0;
      this.price.sale_price = 0;

      // Add a new barcode table
      if (this.nextPriceTableId == 1) {
        const newPriceTable = {
          id: this.nextPriceTableId,
          data: [], // You can fill this with actual barcode data as needed
          fields: [
            { key: "index", label: "Id", _style: "width:1%" },
            {
              key: "sale_price",
              label: "Precio de venta",
              _style: "width:20%;",
            },
            {
              key: "purchase_price",
              label: "Precio de compra",
              _style: "width:20%;",
            },
            { key: "state", label: "Estado", _style: "width:5%;" },
          ],
          name_product: id.name,
        };
        this.priceTables.push(newPriceTable);
        this.mtd_getdata_price(newPriceTable.id, id.id);
        this.nextPriceTableId += 1;
      }
    },
    removePriceTable(tableId) {
      this.nextPriceTableId = 1;
      this.priceTables = this.priceTables.filter(
        (table) => table.id !== tableId
      );

      // Si no quedan más tablas de códigos de barras, restablece el tamaño de la columna principal
      if (this.priceTables.length === 0) {
        this.tableColSize = 12;
      }
    },
    mtd_price: function (action) {
      this.tableColSize = 7;
      this.modal.modal_form_price = true;
      if (action == "update") {
        this.modal.title = "Editar Precio";
        this.modal.action = action;
      } else {
        this.modal.title = "Agregar Precio";
        this.modal.action = "store";
        // this.price.product_id="";
        this.price.purchase_price = 0;
        this.price.sale_price = 0;
        this.price.state = 0;
      }
    },
    mtd_action_price: function () {
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/prices/" + this.modal.action,
        token: this.$store.getters.get__token,
        params: this.price,
      })
        .then((response) => {
          if (this.modal.action == "store") {
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata_price(
              this.priceTables[0].id,
              this.price.product_id
            );
          } else {
            let updatedPrice = response.data.data;
            let index = this.priceTables[0].data.findIndex(
              (price) => price.id === updatedPrice.id
            );
            if (index !== -1) {
              this.$set(this.priceTables[0].data, index, updatedPrice);
            }
            message = "EDITADO CORRECTAMENTE";
            this.mtd_getdata_price(
              this.priceTables[0].id,
              this.price.product_id
            );
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            modal_form_price: false,
          };
          this.price = {
            id: "",
            product_id: "",
            sale_price: 0,
            purchase_price: 0,
            state: 0,
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_getdata_price: function (tableId, productId) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byPrice/" +
          this.$store.getters.get_company.id +
          "/" +
          productId,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // this.data = response.data.data;
          const tableIndex = this.priceTables.findIndex(
            (table) => table.id === tableId
          );
          if (tableIndex !== -1) {
            this.$set(this.priceTables[tableIndex], "data", response.data.data);
            // Agregar un índice a cada elemento en la data
            let index = 1;
            this.priceTables[tableIndex].data.forEach((item) => {
              item.index = index;
              index++;
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_show_price: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/prices/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.price = response;
          this.mtd_price("update");
          // this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_table_productUnit: function (id) {
      if (this.priceTables.length != 0) {
        this.removePriceTable(this.priceTables[0].id);
      }
      if (this.barcodeTables.length != 0) {
        this.removeBarcodeTable(this.barcodeTables[0].id);
      }
      if (this.productUnitTables.length == 1) {
        this.removeProductUnitTable(this.productUnitTables[0].id);
      }

      this.tableColSize = 7;
      this.productUnit.product_id = id.id;
      this.productUnit.unit_id = "";

      // Add a new barcode table
      if (this.nextProductUnitTableId == 1) {
        const newProductUnitTable = {
          id: this.nextProductUnitTableId,
          data: [],
          fields: [
            { key: "index", label: "Id", _style: "width:1%" },
            { key: "unit_name", label: "Unidad", _style: "width:20%;" },
            { key: "state", label: "Estado", _style: "width:5%;" },
          ],
          name_product: id.name,
        };
        this.productUnitTables.push(newProductUnitTable);
        this.mtd_getdata_productUnit(newProductUnitTable.id, id.id);
        this.nextProductUnitTableId += 1;
      }
    },
    removeProductUnitTable(tableId) {
      this.nextProductUnitTableId = 1;
      this.productUnitTables = this.productUnitTables.filter(
        (table) => table.id !== tableId
      );

      // Si no quedan más tablas de códigos de barras, restablece el tamaño de la columna principal
      if (this.productUnitTables.length === 0) {
        this.tableColSize = 12;
      }
    },
    mtd_productUnit: function (action) {
      this.tableColSize = 7;
      this.modal.modal_form_productUnit = true;

      if (action == "update") {
        this.modal.title = "Editar Producto x Unidad";
        this.modal.action = action;
        this.productUnit.unit_id = this.productUnit.unit.id;
      } else {
        this.modal.title = "Agregar Producto x Unidad";
        this.modal.action = "store";
        this.productUnit.unit_id = "";
        this.productUnit.state = 0;
      }
    },
    mtd_action_productUnit: function () {
      let color = "success";
      let message = "";
      this.post({
        url:
          this.$store.getters.get__url + "/productsunits/" + this.modal.action,
        token: this.$store.getters.get__token,
        params: this.productUnit,
      })
        .then((response) => {
          if (this.modal.action == "store") {
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata_productUnit(
              this.productUnitTables[0].id,
              this.productUnit.product_id
            );
          } else {
            let updateProductxUnit = response.data.data;
            let index = this.productUnitTables[0].data.findIndex(
              (productUnit) => productUnit.id === updateProductxUnit.id
            );
            if (index !== -1) {
              this.$set(
                this.productUnitTables[0].data,
                index,
                updateProductxUnit
              );
            }
            message = "EDITADO CORRECTAMENTE";
            this.mtd_getdata_productUnit(
              this.productUnitTables[0].id,
              this.productUnit.product_id
            );
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            modal_form_productUnit: false,
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_action_price: function () {
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/prices/" + this.modal.action,
        token: this.$store.getters.get__token,
        params: this.price,
      })
        .then((response) => {
          if (this.modal.action == "store") {
            message = "REGISTRADO CORRECTAMENTE";
            this.mtd_getdata_price(
              this.priceTables[0].id,
              this.price.product_id
            );
          } else {
            let updatedPrice = response.data.data;
            let index = this.priceTables[0].data.findIndex(
              (price) => price.id === updatedPrice.id
            );
            if (index !== -1) {
              this.$set(this.priceTables[0].data, index, updatedPrice);
            }
            message = "EDITADO CORRECTAMENTE";
            this.mtd_getdata_price(
              this.priceTables[0].id,
              this.price.product_id
            );
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            modal_form_price: false,
          };
          this.price = {
            id: "",
            product_id: "",
            sale_price: 0,
            purchase_price: 0,
            state: 0,
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_getdata_productUnit: function (tableId, productId) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byProductUnit/" +
          this.$store.getters.get_company.id +
          "/" +
          productId,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          const tableIndex = this.productUnitTables.findIndex(
            (table) => table.id === tableId
          );
          if (tableIndex !== -1) {
            this.$set(
              this.productUnitTables[tableIndex],
              "data",
              response.data.data
            );
            let index = 1;
            this.productUnitTables[tableIndex].data.forEach((item) => {
              item.index = index;
              index++;
            });
          }
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_show_productUnit: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/productsunits/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.productUnit = response;
          this.mtd_productUnit("update");
        })
        .catch((errors) => {});
    },
    show_Unit: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/productsunits/byUnit/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.data_units.push({
              label: element.name,
              value: element.id,
              quantity: element.quantity,
            });
          });
        })
        .catch((errors) => {});
    },
    validatePositive(field) {
      if (this.price[field] <= 0) {
        this.price[field] = 1; // Establece el valor mínimo en 1
      }
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>