<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="modal-body">
      <table class="table table-sm table-hover dt-responsive nowrap">
        <thead class="bg-gris color-black">
          <tr class="text">
            <th>N°</th>
            <th>Cliente</th>
            <th>Hora</th>
            <th>Campo Deportivo</th>
            <th>Pagó</th>
            <th>Total</th>
            <th>Estado de Pago</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, index) in cpData" :key="index">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-center">{{ i.name }}</td>
            <td class="text-center">{{ i.start_time }} - {{ i.end_time  }}</td>
            <td class="text-center">{{ i.rate.sport_fieldxsport.sport_field.name }}</td>
            <td class="text-center" v-if="i.sale">{{ sumAllCharges(i.sale.voucher) }}</td>
            <td class="text-center" v-else>0</td>
            <td class="text-center">{{ i.total_pay }}</td>
            <td class="text-center">{{statePay(i.payment ) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger">
        <i class="fa fa-times"></i>&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "c-detalle-reservas",
  components: {
    CModal,
  },
  data() {
    return {

    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: Array,
  },
  computed: {
    cpData() {
      return this.item.map(element => element);
    },
  },
  created() {
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModal");
    },

    statePay(state) {
      switch (state) {
        case 0:
          return "Pendiente";
        case 1:
          return "Pagado Parcialmente";
        case 2:
          return "Pagado";
        default:
          return "-";
      }
    },
    sumAllCharges(vouchers) {
      // Iteramos sobre todos los vouchers y sus charges para sumar los amounts
      return vouchers.reduce((totalAmount, voucher) => {
        const chargeTotal = voucher.charge.reduce((sum, charge) => sum + parseFloat(charge.amount), 0);
        return totalAmount + chargeTotal;
      }, 0).toFixed(2);
    },
  },
  filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
};
</script>

<style scoped>
.bg-purple {
  background-color: #926cdc;
}
.bg-white {
  background-color: white;
}
.bg-gris {
  background-color: #dee2e6;
}
.color-white {
  color: white;
}
.color-black {
  color: black;
}
.font-bold {
  font-weight: bold;
}
.container-type {
  height: 40px;
}
.container-type:hover {
  background-color: #17a2b8; /* bg-info */
  cursor: pointer;
}
.text {
  margin: auto;
}
</style>
