<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
  <div>
    <table class="table table-hover" v-if="type == 'retiro'">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Descripción</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(movement, index) in movements" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ movement.date }}</td>
          <td> {{ movement.user.name }}</td>
          <td>{{ movement.description }}</td>
          <td>{{ movement.amount}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmountMovements() }}</td>
        </tr>
      </tfoot>
    </table>
    <table class="table table-hover" v-else>
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Descripción</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(movement, index) in movements" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ movement.date }}</td>
          <td> {{ movement.user.name }}</td>
          <td>{{ movement.description }}</td>
          <td>{{ movement.amount}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="4" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmountMovements() }}</td>
        </tr>
      </tfoot>
    </table>
  </div>  
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>
<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";

export default {
  name: "c-movement-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
      data_chargue: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    type:String,
    movements: Array,
  },
  watch: {
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalMovement");
    },
    totalAmountMovements() {
      return this.movements?.reduce((total, movement) => {
        return total + parseFloat(movement.amount);
      }, 0).toFixed(2); // Asegúrate de mostrar solo dos decimales
    },
  },
};
</script>
<style scoped>
</style>
