<template>
  <div class="container">
    <CRow class="mt-3">
      <CCol xxl="12" xl="12" md="12" sm="12" class="text-center">
        <h4>¿EN QUÉ SEDE DESEA TRABAJAR?</h4>
        <p>{{ userName }}</p>
      </CCol>
    </CRow>
    <CRow class="mt-3">
      <CCol xxl="3" xl="3" md="3" sm="12"></CCol>
      <CCol xxl="6" xl="6" md="6" sm="12" class="text-center">
        <div class="d-flex justify-content-around">
          <CCard
            v-for="(item, index) in data_campus"
            :key="index"
            class="mr-1 card"
            @click="mtdSetCampu(item)"
            color="gradient-info"
            body-wrapper
            text-color="white"
          >
            {{ item.label }}
          </CCard>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "c-home-campus",

  data(){
    return{
      data_campus:[],
    }
  },
  
  props: {
    // campus: {
    //   type: Array,
    //   default: [],
    // },
    // company: {
    //   type: Object,
    //   default: [],
    // },
  },
  computed: {
    userName() {
      return this.$store.state.user.name;
    },
    userRoles() {
      return this.$store.getters.get_all_user;
    }
  },
  created() {
    if(this.$store.getters.get_all_user.id){
      this.indexCampus();
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdSetCampu: function (payload) {
      this.$emit("mtdSetCampu", payload);
    },
    indexCampus: function(){
      this.get({
        url: this.$store.getters.get__url+"/employeesxcampus/campus/"+ this.$store.getters.get_all_user.id,
        token: this.$store.getters.get__token,
      })
      .then((response) => {
          response.data.campu.forEach((element) => {
            this.data_campus.push({
              label: element.campu_name,
              value: element.campu_id,
            });
          });
        })
        .catch((errors) => {});
    },
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
</style>
