<template>
  <div>
    <CRow>
      <CCol xs="6" sm="6" md="6" lg="6" xl="6" class="mb-2">
        <button type="button" class="btn btn-sm btn-outline-main bg-purple color-white" @click="returnHome">
          <i class="fas fa-home-lg-alt"></i>&nbsp;&nbsp;Inicio
        </button>
      </CCol>
      <CCol xs="6" sm="6" md="6" lg="6" xl="6" class="mb-2 text-right">
        <button @click="mtdOpenModalRetirementDeposit" class="btn btn-sm btn-info btn-md mx-1">
          <b><i class="far fa-money-bill-alt"></i>&nbsp; Depósito/Retiro</b>
        </button>
        <button @click="mtdCloseBead" class="btn btn-sm btn-info btn-md">
          <b><i class="fas fa-cash-register"></i>&nbsp;Cerrar Caja</b>
        </button>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="6" xl="6">
        <CCard>
          <CCardHeader class="bg-gris text-center font-weight-bold">
            <span>Total Efectivo</span>
          </CCardHeader>
          <CCardBody class="border">
            <div>Apertura: {{ this.bead.initial }}</div>
          </CCardBody>
          <CCardBody class="border" style="cursor: pointer" @click="mtdOpenModalIncome('ingreso')">
            <div>Ingresos: {{ this.bead.income }}</div>
          </CCardBody>
          <CCardBody class="border" style="cursor: pointer" @click="mtdOpenModalIncome('egreso')">
            <div>Egresos: {{ this.bead.expense }}</div>
          </CCardBody>
          <CCardBody class="border" style="cursor: pointer" @click="mtdOpenModalMovement('retiro')">
            <div>Retiros: {{ this.sumaRetiro() }}</div>
          </CCardBody>
          <CCardBody class="border" style="cursor: pointer" @click="mtdOpenModalMovement('deposito')">
            <div>Depósito: {{ this.sumaDeposito() }}</div>
          </CCardBody>
          <CCardBody class="border">
            <div>
              <b>Total: {{ this.bead.final }} </b>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="6" xl="6">
        <CCard>
          <CCardHeader class="bg-gris text-center font-weight-bold">
            <span>Otros Ingresos</span>
          </CCardHeader>
          <CCardBody v-for="(method, index) in data_methodpayment" :key="index" class="border" style="cursor: pointer"
            @click="handleMethodPayment(method.name)">
            <div>{{ method.name }}: {{ getMethodValue(method.name) }}</div>
          </CCardBody>
        </CCard>
        <div class="d-flex justify-content-end"></div>
      </CCol>
    </CRow>
    <div class="container mx-auto p-4">
      <cModalIncome :title="modalIncome.title" :boo_modal="modalIncome.modal_form" :incomes="modalIncome.income"
        :incomes_reserva="modalIncome.income_reserva" :type="modalIncome.type" @closeModalIncome="closeModalIncome" />
      <cModalRetirementDeposit :title="modalRetirementDeposit.title" :boo_modal="modalRetirementDeposit.modal_form"
        :bead_id="modalRetirementDeposit.bead_id" :bead_final="modalRetirementDeposit.bead_final"
        @closeModalRetirementDeposit="closeModalRetirementDeposit" />
      <cModalMovement :title="modalMovement.title" :boo_modal="modalMovement.modal_form"
        :movements="modalMovement.movement" :type="modalMovement.type" @closeModalMovement="closeModalMovement" />
      <cModalOtrosIngresos :title="modalData.title" :boo_modal="modalData.modal_form" :data="modalData.data"
        :data_reserva="modalData.data_reserva" @closeModal="closeModal" />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cModalIncome from "../modals/cModalIncome.vue";
import cModalRetirementDeposit from "../modals/cModalRetiremetDeposit.vue";
import cModalMovement from "../modals/cModalMovement.vue";
import cModalOtrosIngresos from '../modals/cModalOtrosIngresos.vue';
export default {
  name: "close-bead",
  components: {
    cModalIncome,
    cModalRetirementDeposit,
    cModalMovement,
    cModalOtrosIngresos,
  },
  data() {
    return {
      prefix: "beads",
      data_methodpayment: [],
      data_charge: [],
      data_ingresos_reserva: [],
      data_transferencia_reserva: [],
      data_tarjeta_reserva: [],
      data_deposito_reserva: [],
      data_yape_reserva: [],
      data_plin_reserva: [],
      data_ingresos: [],
      data_transferencia: [],
      data_tarjeta: [],
      data_deposito: [],
      data_yape: [],
      data_plin: [],
      data_egresos: [],
      verificBead: false,
      bead: {
        id: "",
        box_id: "",
        user_id: this.$store.getters.get__user_id,
        campu_id: this.$store.getters.get_campu.value,
        company_id: this.$store.getters.get_company.id,
        initial: "",
        final: "",
        expense: "",
        income: "",
        card: "",
        turned: "",
        transfer: "",
        retirement: "",
        status_open: "",
        status_close: "",
        deposit: "",
        authorize: "",
        state: "",
      },
      process: {
        box_id: "",
        bead_id: "",
        otroIngresos: [
          {
            label: "Transferencia",
            total: 0,
          },
          {
            label: "Tarjeta",
            total: 0,
          },
          {
            label: "Deposito",
            total: 0,
          },
          {
            label: "Yape",
            total: 0,
          },
          {
            label: "Plin",
            total: 0,
          },
        ],
        observation: "",
        totIncome: 0,
        totExpense: 0,
        retirement: 0,
        deposit_efectivo: 0,
        initial: 0,
        final: 0,
      },
      modalIncome: {
        title: "Ingresos",
        modal_form: false,
        income: [],
        income_reserva: [],
        type: "",
      },
      modalMovement: {
        title: "Retiro",
        modal_form: false,
        movement: [],
        type: "",
      },
      modalRetirementDeposit: {
        title: "",
        modal_form: false,
        bead_id: "",
        bead_final: 0,
      },
      modalData: {
        title: "",
        modal_form: false,
        data: [],
        data_reserva: [],
      },
      incomes: [],
      movement: [],
      movement_retiros: [],
      movement_depositos: [],
    };
  },
  watch: {
    "bead.id": function (newId) {
      if (newId) {
        this.mtdReportBeads();
        this.showChargue();
        this.showMovement();
      }
    },
  },
  created() {
    this.showBead();
    this.showMethodPayment();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("setView", "home");
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.verificBead = response.data ? true : false;
            this.bead = this.verificBead == true ? response.data : {};
          }
        })
        .catch((errors) => { });
    },
    showMethodPayment() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showMethodPayment/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            // console.log(response.data);

            response.data.forEach((arr) => {
              this.data_methodpayment.push(arr);
            })
          }
        })
        .catch((errors) => { });
    },
    mtdReportBeads: function () {
      this.get({
        url:
          this.$store.getters.get__url + "/beads/reportClose/" + this.bead.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.incomes = response.data;
            this.process.initial = this.bead.initial;
            this.process.totIncome = this.bead.income;
            this.process.final = this.bead.final;
            this.process.bead_id = this.bead.id;
            this.process.box_id = this.bead.box_id;
            this.process.deposit_efectivo = this.bead.deposit_efectivo;
            this.process.retirement = this.bead.retirement;
            this.completedJson();
          }
        })
        .catch((errors) => { });
    },
    completedJson: function () {
      const totals = {
        EFECTIVO: 0,
        DEPOSITO: 0,
        TARJETA: 0,
        YAPE: 0,
        TRANSFERENCIA: 0,
        PLIN: 0,
      };

      this.incomes.forEach((element) => {
        if (totals.hasOwnProperty(element.method)) {
          totals[element.method] += parseFloat(element.amount);
        }
      });
      //completar el json
      this.process.otroIngresos.forEach((ele) => {
        if (ele.label == "Transferencia") {
          ele.total = parseFloat(totals["TRANSFERENCIA"]);
        }
        if (ele.label == "Tarjeta") {
          ele.total = parseFloat(totals["TARJETA"]);
        }
        if (ele.label == "Deposito") {
          ele.total = parseFloat(totals["DEPOSITO"]);
        }
        if (ele.label == "Yape") {
          ele.total = parseFloat(totals["YAPE"]);
        }
        if (ele.label == "Plin") {
          ele.total = parseFloat(totals["PLIN"]);
        }
      });
    },
    mtdCloseBead() {
      this.post({
        url: this.$store.getters.get__url + "/beads/close",
        token: this.$store.getters.get__token,
        params: {
          bead: this.bead,
          process: this.process,
        },
      })
        .then((response) => {
          if (response.statusCode == 200) {
            Swal.fire({
              title: "Caja Cerrada Correctamente",
              icon: "success",
            });
            //volver
            this.exportPdf();
            this.returnHome();
          }
        })
        .catch((errors) => {
          Swal.fire({
            title: errors,
            icon: "danger",
          });
        });
    },
    handleMethodPayment(methodName) {
      switch (methodName) {
        case 'TRANSFERENCIA':
          this.mtdOpenModal('transfer');
          break;
        case 'TARJETA':
          this.mtdOpenModal('card');

          break;
        case 'DEPOSITO':
          this.mtdOpenModal('deposit');
          break;
        case 'YAPE':
          this.mtdOpenModal('yape');
          break;
        case 'PLIN':
          this.mtdOpenModal('plin');
          break;
        default:
          console.warn("Método de pago no soportado:", methodName);
      }
    },
    getMethodValue(methodName) {
      switch (methodName) {
        case 'TRANSFERENCIA':
          return this.bead.transfer;
        case 'TARJETA':
          return this.bead.card;
        case 'DEPOSITO':
          return this.bead.deposit;
        case 'YAPE':
          return this.sumaYape();
        case 'PLIN':
          return this.sumaPlin();
        default:
          return null;
      }
    },
    mtdOpenModal(type) {
      let title, data, data_reserva;

      switch (type) {
        case "transfer":
          title = "Transferencia";
          data = this.data_transferencia;
          data_reserva = this.data_transferencia_reserva;
          break;
        case "card":
          title = "Tarjeta";
          data = this.data_tarjeta;
          data_reserva = this.data_tarjeta_reserva;
          break;
        case "deposit":
          title = "Deposito";
          data = this.data_deposito;
          data_reserva = this.data_deposito_reserva;
          break;
        case "yape":
          title = "Yape";
          data = this.data_yape;
          data_reserva = this.data_yape_reserva;
          break;
        case "plin":
          title = "Plin";
          data = this.data_plin;
          data_reserva = this.data_plin_reserva;
          break;
        default:
          return;
      }

      this.modalData = {
        title: title,
        modal_form: true,
        data: data,
        data_reserva: data_reserva,
      };
    },
    closeModal() {
      this.modalData.modal_form = false;
    },
    mtdOpenModalIncome(type) {
      this.modalIncome = {
        title: type == "ingreso" ? "Ingresos" : "Egresos",
        modal_form: true,
        income: type == "ingreso" ? this.data_ingresos : this.data_egresos,
        income_reserva: type == "ingreso" ? this.data_ingresos_reserva : [],
        type: type,
      };
    },
    closeModalIncome() {
      this.modalIncome.modal_form = false;
    },
    mtdOpenModalMovement(type) {
      this.modalMovement = {
        title: type == "retiro" ? "Retiro" : "Depósito",
        modal_form: true,
        movement: type == "retiro" ? this.movement_retiros : this.movement_depositos,
        type: type,
      };
    },
    closeModalMovement() {
      this.modalMovement.modal_form = false;
    },
    mtdOpenModalRetirementDeposit() {
      this.modalRetirementDeposit = {
        title: "Registrar Retiros o Depósitos",
        modal_form: true,
        bead_id: this.bead.id ? this.bead.id : "",
        bead_final: this.bead.final ? this.bead.final : 0.00,
      };
    },
    closeModalRetirementDeposit() {
      this.modalRetirementDeposit = {
        modal_form: false,
      };
      this.showBead();
      this.movement = [];
      this.movement_depositos = [];
      this.movement_retiros = [];
      this.showMovement();
      this.sumaDeposito();
      this.sumaRetiro();
    },
    showMovement() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/filterMovement/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.bead.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.movement = response.data;
            this.movement.forEach((item) => {
              if (item.type == 1) {
                this.movement_depositos.push(item);
              } else if (item.type == 2) {
                this.movement_retiros.push(item);
              }
            })
          }
        })
        .catch((errors) => { });
    },
    showChargue() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/filterChargue/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.bead.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.data_charge = response.data.charge;
            this.data_egresos = response.data.cancels;
            this.filterIncomes();
          }
        })
        .catch((errors) => { });
    },
    filterIncomes() {
      this.data_charge.forEach((element) => {
        if (element.voucher.sale.type == 1) {
          if (element.method == "EFECTIVO") {
            this.data_ingresos_reserva.push(element);
          }
          if (element.method == "TRANSFERENCIA") {
            this.data_transferencia_reserva.push(element);
          }
          if (element.method == "TARJETA") {
            this.data_tarjeta_reserva.push(element);
          }
          if (element.method == "DEPOSITO") {
            this.data_deposito_reserva.push(element);
          }
          if (element.method == "YAPE") {
            this.data_yape_reserva.push(element);
          }
          if (element.method == "PLIN") {
            this.data_plin_reserva.push(element);
          }
        }

        if (element.voucher.sale.type == 0) {
          if (element.method == "EFECTIVO") {
            this.data_ingresos.push(element);
          }
          if (element.method == "TRANSFERENCIA") {
            this.data_transferencia.push(element);
          }
          if (element.method == "TARJETA") {
            this.data_tarjeta.push(element);
          }
          if (element.method == "DEPOSITO") {
            this.data_deposito.push(element);
          }
          if (element.method == "YAPE") {
            this.data_yape.push(element);
          }
          if (element.method == "PLIN") {
            this.data_plin.push(element);
          }
        }
      });
    },
    sumaYape() {
      const allYapeData = [...this.data_yape, ...this.data_yape_reserva];
      const suma = allYapeData.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
      const total = suma.toFixed(2);
      return total;
    },
    sumaPlin() {
      const allPlinData = [...this.data_plin, ...this.data_plin_reserva];
      const suma = allPlinData.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
      const total = suma.toFixed(2);
      return total;
    },
    sumaRetiro() {
      const suma = this.movement_retiros.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
      const total = suma.toFixed(2);
      return total;
    },
    sumaDeposito() {
      const suma = this.movement_depositos.reduce(
        (total, item) => total + parseFloat(item.amount),
        0
      );
      const total = suma.toFixed(2);
      return total;
    },
    exportPdf: function () {
      window.open(
        this.$store.getters.get__url +
        "/beads/viewpdfreportegeneral/" +
        this.$store.getters.get_campu.value +
        "/" +
        this.bead.id,
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gris {
  background-color: #dee2e6;
}

.bg-purple {
  background-color: #926cdc;
}

.color-white {
  color: white;
}
</style>