<template>
  <div>
    <div class="row" id="div_conte2">
      <div id="div_conte" class="col-md-10 mx-auto">
        <div class="bg-secondary text-white bg-opacity-10">
          <div class="text-center py-4 px-5" id="scroll-div">
            <div class="row">
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('roles')"
                v-if="hasRoles([1, 2])"
              >
                ROLES
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('users')"
                v-if="hasRoles([1, 2])"
              >
                USUARIOS
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('company')"
                v-if="hasRoles([1])"
              >
                EMPRESA
              </button>
              <!-- <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('boxes')"
                v-if="hasRoles([2])"
              >
                CAJAS
              </button> -->
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('campus')"
                v-if="hasRoles([1, 2])"
              >
                SEDES
              </button>
            </div>
            <div class="row">
              <!-- <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('almacen')"
                v-if="hasRoles([2])"
              >
                ALMACEN
              </button> -->
              
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('schedule')"
                v-if="hasRoles([1, 2])"
              >
                HORARIO
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('sport')"
                v-if="hasRoles([1, 2])"
              >
                DEPORTE
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('type_of_field')"
                v-if="hasRoles([1, 2])"
              >
                TIPO DE CAMPO
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('sport_field')"
                v-if="hasRoles([1, 2])"
              >
                CAMPO DEPORTIVO
              </button>
            </div>
            <div class="row">
              
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('rate')"
                v-if="hasRoles([1, 2])"
              >
                TARIFA
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('employee')"
                v-if="hasRoles([1, 2])"
              >
                TRABAJADOR
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('employeexcampu')"
                v-if="hasRoles([1,2])"
              >
                TRABAJADOR X SEDE
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('document_type')"
                v-if="hasRoles([1, 2])"
              >
                TIPO DE DOCUMENTO
              </button>
            </div>
            <div class="row">
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('method_payment')"
                v-if="hasRoles([2])"
              >
                METODO DE PAGO
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('unit')"
                v-if="hasRoles([2])"
              >
                UNIDAD
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('product')"
                v-if="hasRoles([2])"
              >
                PRODUCTO
              </button>
              <button
                class="div-button col m-2 p-2 text-white"
                @click="changePage('provider')"
                v-if="hasRoles([2])"
              >
                PROVEEDOR
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  props: {
    userRoles: {
      type: Array,
      required: true,
    },
  },
  created() {
    this.test();
  },
  methods: {
    changePage: function (page) {
      this.$emit("changePage", page);
    },
    hasRoles(roles) {
      return roles.some((role) => this.userRoles.includes(role));
    },
    test: function () {},
  },
};
</script>

<style scoped>
#div_conte {
  border: 1.5px solid #926cdc;
  padding: 0;
  margin: 40px 0;
}
.div-button {
  background: #926cdc;
  border-radius: 15px;
  border: none;
}
#scroll-div {
  height: auto;
  overflow-y: scroll;
}
#scroll-div > div > button {
  height: 65px;
}
@media (max-width: 1320px) {
  #div_conte {
    border: 1.5px solid #926cdc;
    padding: 0;
    margin: 60px 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .row {
    display: block;
  }
  #div_conte {
    border: 1.5px solid #926cdc;
    padding: 0;
    margin: 100px 0;
  }
  #scroll-div {
    height: 518px;
  }
}
@media (max-width: 768px) {
  #div_conte2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row {
    display: block;
  }
  #scroll-div > div > button {
    height: 44px;
  }
  .container {
    font-size: 11px;
  }
  #scroll-div {
    height: 450px;
  }
}
@media (max-width: 415px) {
  #scroll-div {
    height: 450px;
  }
}
</style>
