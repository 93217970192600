<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :title="title" :size="'md'" :centered="false" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="12">
            <CInput type="number" label="Monto Inicial" v-model="bead.monto" :disabled="!newBead" @keyup="validatePositive('monto')" />
        </CCol>
    </CRow>
    <template #footer>
        <button @click="mtdClose" class="btn btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button @click="mtd_action" class="btn btn-info btn-sm">Guardado</button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import {
    mapActions
} from "vuex";
import {
    bus
} from "../../../main";
export default {
    name: "c-open-bead-modal",
    components: {
        CModal,
    },
    data() {
        return {
            prefix: "beads",
            bead: {
                box_id: "",
                user_id: this.$store.getters.get__user_id,
                campu_id: this.$store.getters.get_campu.value,
                monto: "",
                expense: "",
                income: "",
                turned: "",
                card: "",
                retirement: "",
                status_open: "",
                status_close: "",
                deposit: "",
                authorize: "",
                state: "",
            },
            process: {
                box_id: "",
                bead_id: 0,
                otroIngresos: [{
                        label: "Transferencia",
                        total: 0,
                    },
                    {
                        label: "Tarjeta",
                        total: 0,
                    },
                    {
                        label: "Deposito",
                        total: 0,
                    },
                    {
                        label: "Yape",
                        total: 0,
                    },
                    {
                        label: "Plin",
                        total: 0,
                    },
                ],
                observation: "",
                totIncome: 0,
                totExpense: 0,
                retirement: 0,
                deposit_efectivo: 0,
                initial: 0,
                final: 0,
            },
            newBead: true,
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
    },
    watch: {
        boo_modal(newVal) {
            if (newVal == true) {
                this.filterBox();

            }
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.resetFormData();
            this.$emit("closeModalOpenBead");
        },
        resetFormData() {
            this.bead = {
                box_id: "",
                user_id: this.$store.getters.get__user_id,
                campu_id: this.$store.getters.get_campu.value,
                monto: "",
                status_open: "",
                status_close: "",
                state: "",
            };
        },
        mtd_action() {
            this.post({
                    url: this.$store.getters.get__url + "/" + this.prefix + "/store",
                    token: this.$store.getters.get__token,
                    params: {
                        bead: this.bead,
                        process: this.process,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        let color = "success";
                        let message = "REGISTRADO CORRECTAMENTE";
                        // Reseteamos la data
                        this.resetFormData();
                        this.$emit("save");
                        bus.$emit("alert", {
                            color: color,
                            message: message,
                        });
                    }
                })
                .catch((errors) => {
                    bus.$emit("alert", {
                        color: "danger",
                        message: errors,
                    });
                });
        },
        filterBox() {
            this.get({
                    url: this.$store.getters.get__url +
                        "/" +
                        this.prefix +
                        "/filterBox/" +
                        this.$store.getters.get_campu.value,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    let box = response.data.data;
                    this.bead.box_id = box.id;
                    this.bead.monto = response.data.initialPrevious;
                    this.newBead = response.data.new;
                    this.process.box_id = box.id;
                })
                .catch((erros) => {});
        },
        validatePositive(field) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");
            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf("."); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
            }
            this.bead[field] = sanitizedValue;
        },
    },
};
</script>

<style scoped>
</style>
