<template>
  <div class="fontfamily-sans-serif">
    <div class="container d-flex justify-content-around">
      <CRow>
        <CCol xs="12" sm="12" md="8" lg="8" xl="8">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <b><i class="fas fa-store"></i>&nbsp;Reservar</b>
              <button
                type="button"
                class="btn btn-sm btn-outline-main bg-purple color-white"
                @click="returnHome"
              >
                <i class="fas fa-home-lg-alt"></i>&nbsp;&nbsp;Inicio
              </button>
            </CCardHeader>
            <CCardBody>
              <FullCalendar :options="calendarOptions" />
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs="12" sm="12" md="4" lg="4" xl="4">
          <CCard>
            <CCardHeader class="d-flex justify-content-between">
              <span
                ><i class="fas fa-store"></i>&nbsp;Sede:
                {{
                  this.$store.getters.get_campu
                    ? this.$store.getters.get_campu.label
                    : ""
                }}</span
              >
              {{ booking.reservation_date }}
            </CCardHeader>
            <CCardBody>
              <div class="row d-flex" style="padding: 10px">
                <div
                  v-for="(item, index) in cantBookings"
                  :key="index"
                  class="col-md-12 col-xl-4 col-lg-6 col-sm-12"
                  :class="['d-flex', 'bg-gris', 'container-type']"
                >
                  <div class="text text-center">{{ item.label }}</div>
                  <span
                    class="badge text rounded-span"
                    :class="
                      item.label == 'Reservado'
                        ? 'text-span'
                        : item.label == 'En curso'
                        ? 'text-span-curso'
                        : 'text-span-ter'
                    "
                    >{{ item.quantity }}</span
                  >
                </div>
              </div>
              <CRow>
                <CCol sm="12" xl="6">
                  <cSelectForm
                    label="Campo Deportivo"
                    placeholder="Seleccione campo deportivo"
                    :options="data_sport_field"
                    :value.sync="booking.sport_field_id"
                    :disabled="!this.attention"
                    @change="mtdSelectCampo"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" xl="6">
                  <cSelectForm
                    label="Deporte"
                    placeholder="Seleccione deporte"
                    :options="data_sport"
                    :value.sync="booking.sport_id"
                    :disabled="!booking.sport_field_id"
                    @change="mtdSelectSport"
                    size="sm"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12" xl="4">
                  <CInput
                    label="Hora de inicio"
                    type="time"
                    v-model="booking.start_time"
                    :disabled="!booking.sport_id"
                    @blur="determineShift"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" xl="4">
                  <cSelectForm
                    label="Horas"
                    :placeholder="customHoursPlaceholder"
                    :options="data_hours"
                    :value.sync="booking.cant_hours"
                    :disabled="!booking.start_time"
                    @change="hanldeElecction"
                    size="sm"
                  />
                </CCol>
                <CCol sm="12" xl="4">
                  <CInput
                    label="Hora Final"
                    type="time"
                    v-model="booking.end_time"
                    readonly
                    size="sm"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="4">
                  <CInput
                    label="Precio"
                    v-model="booking.total_pay"
                    readonly
                    size="sm"
                  />
                </CCol>

                <CCol sm="8" class="d-flex align-items-center">
                  <CInput
                    label="Dni"
                    placeholder="Digite dni"
                    v-model="booking.sport_man_dni"
                    maxlength="8"
                    size="sm"
                    @keyup="mtdSearchDocument"
                  />
                </CCol>
                <CCol sm="12">
                  <CInput
                    label="Nombre"
                    placeholder="Digite nombre"
                    v-model="booking.sport_man_name"
                    :disabled="booking.existingSportman"
                    size="sm"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                    label="Celular"
                    placeholder="Digite celular"
                    v-model="booking.sport_man_phone"
                    maxlength="9"
                    @input="validatePhone"
                    :disabled="!booking.sport_man_name"
                    size="sm"
                  />
                  <span v-if="errors.phone" class="text-danger">{{
                    errors.phone
                  }}</span>
                </CCol>
                <CCol sm="6">
                  <div @click="mtd_action">
                    <label for="">&nbsp;</label>
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-main bg-purple color-white mt-4"
                      :disabled="!canSave"
                    >
                      <i class="fas fa-calendar-check"></i> Guardar
                    </button>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
    <div class="container mx-auto p-4">
      <cModalDetalleReserva
        :title="modal.title"
        :boo_modal="modal.modal_form"
        :item="modal.item"
        @closeModal="closeModal"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { bus } from "../../../main";
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import cSelectForm from "../../shared/inputs/cSelect.vue";
import cModalDetalleReserva from "../modals/cModalDetalleReserva.vue";
import moment from "moment";
import { CCol } from "@coreui/vue-pro";

export default {
  name: "bookings",
  components: {
    FullCalendar,
    cSelectForm,
    cModalDetalleReserva,
  },
  data() {
    return {
      prefix: "bookings",
      showCalendar: false,
      customHoursPlaceholder: "Seleccione cant. de horas",
      cantBookings: [
        {
          label: "Reservado",
          quantity: 0,
        },
        {
          label: "En curso",
          quantity: 0,
        },
        {
          label: "Finalizado",
          quantity: 0,
        },
      ],
      data_sport_field: [],
      data_sport: [],
      data_rate: [],
      data_rates: [],
      data_sportman: [],
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar Usuario",
          action: "mtd_action_sportsman",
          icon: "far fa-file-image",
        },
      ],
      shifts: {
        early_morning: { start: "00:00", end: "05:59" },
        morning: { start: "06:00", end: "11:59" },
        afternoon: { start: "12:00", end: "17:59" },
        night: { start: "18:00", end: "23:59" },
      },

      calendarOptions: {
        locale: "es",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        events: [],
        initialView: "dayGridMonth",
        buttonText: {
          today: "Hoy",
          month: "Meses",
          week: "Semanas",
          day: "Días",
        },
        headerToolbar: {
          left: "title",
          center: "",
          left: "prev,next today",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        weekends: true,
        selectable: true,
        editable: false,
        validRange: {
          start: Date.now(),
        },
        dateClick: this.dateClick,
        eventClick: this.handleEventClick,

        dayMaxEventRows: true,
        eventLimit: true,
        views: {
          timeGridWeek: {
            dayMaxEventRows: 5,
          },
        },
      },
      dateSend: "",
      events: [],
      booking: {
        id: "",
        sport_field_id: "",
        sport_id: "",
        rate_id: "",
        sport_fieldxsport_id: "",
        reservation_date: "",
        reservation_day: "",
        sport_man_id: "",
        sport_man_name: "",
        sport_man_phone: "",
        sport_man_address: "",
        sport_man_dni: "",
        employee_id: "",
        start_time: "",
        end_time: "",
        cant_hours: "",
        subtotal_pay: "",
        total_pay: "",
        existingSportman: true,
        existingPhone: true,
      },
      schedule: {
        id: "",
        start_time: "",
        end_time: "",
        shift: "",
      },
      sport: {
        id: "",
        name: "",
      },
      quote: {
        medic_id: null,
        dateStr: "",
        dateDay: "",
        campu_id: null,
        turn: 1,
      },
      errors: {
        phone: "",
      },
      data_hours: [
        { value: "00:30", label: "30 min" },
        { value: "01:00", label: "1 Hora" },
        { value: "01:30", label: "1 Hora y 30 min" },
        { value: "02:00", label: "2 Horas" },
        { value: "Otros", label: "Otros" },
      ],
      attention: false,
      ofCall: [],
      modal: {
        title: "",
        item: [],
        modal_form: false,
      },
    };
  },
  computed: {
    canSave() {
      return (
        this.booking.sport_field_id &&
        this.booking.sport_id &&
        this.booking.start_time &&
        this.booking.cant_hours &&
        this.booking.total_pay &&
        this.booking.sport_man_dni &&
        this.booking.sport_man_name &&
        this.booking.sport_man_phone &&
        !this.errors.phone
      );
    },
    canSearch() {
      return this.booking.sport_man_dni.length === 8;
    },
    computedRateLabel() {
      return this.data_rate.length > 0 ? this.data_rate[0].label : "";
    },
  },
  watch: {
    events: {
      handler(newEvents) {
        this.calendarOptions.events = newEvents;
      },
      deep: true,
    },
  },
  created() {
    // this.indexSport_Field();
    this.indexRate();
    this.get_employeeid();
    this.getCurrentTime();
    this.mtdGetHour();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("setView", "home");
    },
    hanldeElecction() {
      if (this.booking.cant_hours == "Otros") {
        this.booking.cant_hours = "Otros";
        this.customHoursPlaceholder = "Seleccione cant. de horas";
        this.openCustomHourModal();
      } else {
        this.calculateEndTime(this.booking.cant_hours);
      }
    },
    openCustomHourModal() {
      Swal.fire({
        title: "Ingrese una hora personalizada (HH:mm)",
        input: "text",
        inputPlaceholder: "HH:mm",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          const customHour = result.value;
          if (this.validateCustomHour(customHour)) {
            if (this.isWithinHourLimits(customHour)) {
              this.booking.cant_hours = customHour;
              // const [hours, minutes] = customHour.split(":").map(Number);
              // this.customHoursPlaceholder = `${hours} horas y ${minutes} min`;
              this.customHoursPlaceholder = `${customHour}`;
              this.calculateEndTime(this.booking.cant_hours);
            } else {
              Swal.fire(
                "Hora inválida",
                "Por favor, ingrese un valor entre 30 minutos y 6 horas",
                "error"
              );
              this.booking.cant_hours = "";
              this.customHoursPlaceholder = "Seleccione cant. de horas";
              this.calculateEndTime(this.booking.cant_hours);
            }
          } else {
            Swal.fire(
              "Hora inválida",
              "Por favor, ingrese una hora en formato HH:mm",
              "error"
            );
            this.booking.hours = "";
            this.customHoursPlaceholder = "Seleccione cant. de horas";
            this.calculateEndTime(this.booking.cant_hours);
          }
        }
      });
    },
    isWithinHourLimits(hourString) {
      const [hours, minutes] = hourString.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;
      return totalMinutes >= 30 && totalMinutes <= 360;
    },
    validateCustomHour(customHour) {
      const timePattern = /^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
      // Si no cumple el patrón, limpia el campo y retorna false
      if (!timePattern.test(customHour)) {
        this.booking.cant_hours = ""; // Borra el valor si no es válido
        return false;
      }
      return true;
    },
    dateClick: function (info) {
      // Reset background color for all dates
      const allDates = document.querySelectorAll(".fc-daygrid-day");
      allDates.forEach((dateElement) => {
        dateElement.style.backgroundColor = ""; // Reset to default
      });
      // Change background color for the selected date
      info.dayEl.style.backgroundColor = "lightblue"; // Or any color you prefer

      const date2 = new Date(info.date);
      const auxiliar = this.fixDate(date2, "date-en");
      this.quote.dateStr = moment(auxiliar).format("DD/MM/YYYY");
      this.booking.reservation_date = this.quote.dateStr;
      this.dateSend = moment(auxiliar).format("YYYY-MM-DD");
      this.quote.dateDay = moment(auxiliar).format("dddd");
      this.booking.reservation_day = this.quitarTildes(this.quote.dateDay);
      //resetar los valores de las cantidades
      this.cantBookings.forEach((element) => {
        element.quantity = 0;
      });
      this.showTime();
    },
    //Selecciona día y que me cargue el horario de la sede
    showTime: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/campu/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.booking.reservation_day +
          "/" +
          this.dateSend,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "No hay atención",
              icon: "warning",
            });
            this.attention = false;
          } else {
            // this.booking.reservation_date = this.quote.dateStr;
            let array = response.data.bookings;
            //cantidad de reseervas en ese día
            if (array.length > 0) {
              //colocar todos los datos
              array.forEach((element) => {
                const index = element.state - 1;
                this.cantBookings[index].quantity = element.total;
              });
            } else {
              this.cantBookings.forEach((element) => {
                element.quantity = 0;
              });
            }
            this.attention = true;
            response.data.data.forEach((element) => {
              this.schedule.start_time = element.start_time;
              this.schedule.end_time = element.end_time;
            });
          }
        })
        .catch((errors) => {});
    },
    indexRate: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/rate/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_rates = [];
          response.data.rates.forEach((element) => {
            this.data_rates.push({
              value: element.id,
              shift: element.shift,
              price: element.price,
              sport_fieldxsport_id: element.sport_fieldsxsport_id,
              detail: element,
            });
          });
          this.bySport_Field();
        })
        .catch((errors) => {});
    },
    bySport_Field: function () {
      this.data_sport_field = [];
      const addedFields = new Set(); // Para almacenar los IDs de campos deportivos agregados

      this.data_rates.forEach((arr) => {
        const sportFieldId = arr.detail.sport_fieldxsport.sport_field_id;
        if (!addedFields.has(sportFieldId)) {
          // Verificar si el campo ya fue agregado
          addedFields.add(sportFieldId);
          const { id, name } = arr.detail.sport_fieldxsport.sport_field;
          this.data_sport_field.push({
            label: name,
            value: id,
          });
        }
      });
      this.mtdSelectCampo();
    },
    /* select campo*/
    mtdSelectCampo: function () {
      this.data_sport = [];
      let sport_id = -1;
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
          this.booking.sport_field_id
        ) {
          if (arr.detail.sport_fieldxsport.sport.id != sport_id) {
            sport_id = arr.detail.sport_fieldxsport.sport.id;
            const { id, name } = arr.detail.sport_fieldxsport.sport;
            this.data_sport.push({
              label: name,
              value: id,
            });
          }
        }
      });
      this.mtdSelectSport();
    },

    /*select sport y guardo el id de sport_fieldxsport en this.booking*/
    mtdSelectSport: function () {
      this.booking.cant_hours = "";
      this.booking.end_time = "";
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
            this.booking.sport_field_id &&
          arr.detail.sport_fieldxsport.sport_id == this.booking.sport_id
        ) {
          this.booking.sport_fieldxsport_id = arr.detail.sport_fieldxsport.id;
        }
      });
    },

    // Determinar si la hora elegida es mañana, tarde o noche
    determineShift: function () {
      const currentTime = this.getCurrentTime();
      const selectedTime = this.booking.start_time;

      // Obtener la fecha seleccionada
      const selectedDate = this.booking.reservation_date;

      const today = new Date();
      const auxiliar = this.fixDate(today, "date-en");
      const todayFormateado = moment(auxiliar).format("DD/MM/YYYY");

      // Si la fecha seleccionada es hoy, validar la hora
      if (selectedTime < currentTime && selectedDate === todayFormateado) {
        Swal.fire({
          title: "La hora seleccionada no puede ser anterior a la hora actual.",
          icon: "warning",
        });
        this.booking.start_time = "";
        return;
      }
      const startTime = moment(this.booking.start_time, "HH:mm");

      const { early_morning, morning, afternoon, night } = this.shifts;

      if (
        startTime.isBetween(
          moment(early_morning.start, "HH:mm"),
          moment(early_morning.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Noche";
      }

      if (
        startTime.isBetween(
          moment(morning.start, "HH:mm"),
          moment(morning.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Dia";
      }
      if (
        startTime.isBetween(
          moment(afternoon.start, "HH:mm"),
          moment(afternoon.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Tarde";
      }
      if (
        startTime.isBetween(
          moment(night.start, "HH:mm"),
          moment(night.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Noche";
      }
      this.validateHoursInicio();
      // Resetting other fields
      this.booking.cant_hours = "";
      this.booking.end_time = "";
      this.booking.total_pay = "";
    },
    //Validar si la sede abre a la hora escogida
    validateHoursInicio: function () {
      const openingTime = this.formatTime(this.schedule.start_time);
      const closingTime = this.formatTime(this.schedule.end_time);
      const selectedTime = this.formatTime(this.booking.start_time);

      // Verificar si el horario cubre las 24 horas (mismo inicio y fin)
      if (openingTime === closingTime) {
        // Si es 24 horas, cualquier hora seleccionada es válida
        return;
      }
      // Si el horario de cierre es después de la medianoche
      if (closingTime < openingTime) {
        // Si el tiempo seleccionado no está en el rango de (hora apertura -> medianoche) o (medianoche -> hora cierre)
        if (!(selectedTime >= openingTime || selectedTime < closingTime)) {
          Swal.fire({
            title: "La sede abre a las " + openingTime,
            icon: "warning",
          });
          this.booking.start_time = "";
        }
      } else {
        // Comparación normal si el horario de cierre no cruza la medianoche
        if (selectedTime < openingTime || selectedTime >= closingTime) {
          Swal.fire({
            title: "La sede abre a las " + openingTime,
            icon: "warning",
          });
          this.booking.start_time = "";
        }
      }
    },
    formatTime: function (timeString) {
      // Dividir la cadena de tiempo en horas, minutos y segundos
      const [hours, minutes] = timeString.split(":");
      // Formatear la hora a "HH:MM"
      return `${hours}:${minutes}`;
    },
    // Calcula a que hora acaba la reservación. Se necesita(hora de inicio, cantidad de horas)
    calculateEndTime: function (event) {
      if (!event) {
        return;
      }
      // Obtener la hora de inicio (start_time)
      const [startHours, startMinutes] = this.booking.start_time
        .split(":")
        .map(Number);
      const totalStartMinutes = startHours * 60 + startMinutes;

      // Obtener la duración ingresada (event.target.value)
      const [durationHours, durationMinutes] = event.split(":").map(Number);
      const totalDurationMinutes = durationHours * 60 + durationMinutes;

      // Sumar los minutos de inicio y duración
      let totalEndMinutes = totalStartMinutes + totalDurationMinutes;

      // Ajustar si el tiempo supera las 24 horas (1440 minutos)
      totalEndMinutes = totalEndMinutes % 1440;

      // Convertir los minutos totales a horas y minutos
      const endHours = Math.floor(totalEndMinutes / 60);
      const endMinutes = totalEndMinutes % 60;

      // Formatear las horas y minutos para que tengan siempre dos dígitos
      const formattedHours = endHours.toString().padStart(2, "0");
      const formattedMinutes = endMinutes.toString().padStart(2, "0");

      // Actualizar el valor de end_time
      this.booking.end_time = `${formattedHours}:${formattedMinutes}`;
      this.showPrice();
    },

    showPrice: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/price/" +
          this.booking.reservation_date +
          "/" +
          this.booking.start_time +
          "/" +
          this.booking.end_time +
          "/" +
          this.booking.reservation_day +
          "/" +
          this.schedule.shift +
          "/" +
          this.booking.sport_fieldxsport_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Canchita Ocupada",
              text:
                response.data.data.start_time +
                " hasta " +
                response.data.data.end_time,
              icon: "warning",
            });
          } else if (response.data.state == 2) {
            Swal.fire({
              title: "Agregue Tarifa",
              icon: "warning",
            });
            this.booking.reservation_date = this.quote.dateStr;
          } else {
            response.data.data.forEach((element) => {
              this.booking.rate_id = element.id;
              this.booking.subtotal_pay = element.price;
            });
            let subtotalPay = parseFloat(this.booking.subtotal_pay);
            let hours = this.convertToDecimal(this.booking.cant_hours);
            // Calcula el total a pagar basado en la duración en horas
            this.booking.total_pay = (subtotalPay * hours).toFixed(2);
          }
        })
        .catch((errors) => {});
    },
    // Función para convertir horas a formato decimal
    convertToDecimal: function (time) {
      const [hours, minutes] = time.split(":").map(Number);
      return hours + minutes / 60; // Convierte los minutos a fracción de hora
    },
    get_employeeid: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/employee/" +
          this.$store.getters.get__user_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.booking.employee_id = element.id;
          });
        })
        .catch((errors) => {});
    },

    mtd_action: function () {
      if (!this.validateForm()) return;
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/bookings/store",
        token: this.$store.getters.get__token,
        params: this.booking,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Canchita Ocupada",
              icon: "warning",
            });
          } else {
            let color = "";
            let message = "";
            if (response.statusCode == 200) {
              color = "success";
              message = "REGISTRADO CORRECTAMENTE";
            } else {
              color = "danger";
              message = "error";
            }
            this.booking = {
              id: "",
              sport_field_id: "",
              sport_id: "",
              rate_id: "",
              sport_fieldxsport_id: "",
              reservation_date: "",
              reservation_day: "",
              start_time: "",
              end_time: "",
              cant_hours: "",
              subtotal_pay: "",
              total_pay: "",
              sport_man_id: "",
              sport_man_name: "",
              sport_man_phone: "",
              sport_man_address: "",
              sport_man_dni: "",
              employee_id: "",
              existingSportman: true,
              existingPhone: true,
            };
            this.cantBookings = [
              {
                label: "Reservado",
                quantity: 0,
              },
              {
                label: "En curso",
                quantity: 0,
              },
              {
                label: "Finalizado",
                quantity: 0,
              },
            ];
            //limpiar el color
            const allDates = document.querySelectorAll(".fc-daygrid-day");
            allDates.forEach((dateElement) => {
              dateElement.style.backgroundColor = ""; // Reset to default
            });
            this.attention = false;
            bus.$emit("alert", {
              color: color,
              message: message,
            });
            this.events.splice(0, this.events.length);
            this.mtdGetHour();
          }
          this.get_employeeid();
        })
        .catch((errors) => {
          console.error(errors);
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    filtrarPhone: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/sportman/" +
          this.booking.sport_man_dni,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            this.booking.sport_man_phone = "";
            this.booking.sport_man_address = "";
          } else {
            response.data.data.forEach((element) => {
              this.booking.sport_man_phone = element.phone;
              this.booking.sport_man_id = element.id;
            });
          }
        })
        .catch((errors) => {});
    },

    fixDate(date, type) {
      let dateFixed = "";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = "";
      switch (type) {
        case "date-es":
          if (date.getDate() < 10) day = "0" + day;
          dateFixed = `${day}/${month}/${year}`;
          break;
        case "date-en":
          if (date.getDate() < 10) day = "0" + day;
          dateFixed = `${year}-${month}-${day}`;
          break;
        case "time":
          date.getHours() < 10
            ? (hours += "0" + date.getHours())
            : (hours = date.getHours());
          dateFixed = `${hours}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
          break;

        default:
          break;
      }
      return dateFixed;
    },
    mtdSearchDocument: function () {
      if (this.booking.sport_man_dni.length == 8) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/Consultas/" +
            this.booking.sport_man_dni,
          token: this.$store.getters.get__token,
        }).then((response) => {
          if (response.data.boo == 3) {
            this.booking.sport_man_dni = "";
            this.booking.sport_man_name = "";
            this.booking.sport_man_address = "";
            this.booking.existingSportman = false;

            // this.$refs.SweetAlert.showWarning("DNI no encontrado");
            Swal.fire({
              title: "DNI no encontrado",
              icon: "warning",
            });
          } else {
            this.booking.sport_man_name =
              response.data.name + " " + response.data.last_name;
            this.booking.sport_man_address = response.data.direccion;
            this.filtrarPhone();
          }
        });
      }
    },
    quitarTildes: function (cadena) {
      return cadena.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    validateDNI: function (dni) {
      const dniPattern = /^[0-9]{8}$/;
      return dniPattern.test(dni);
    },
    validatePhone() {
      if (
        this.booking.sport_man_phone.length != 9 ||
        isNaN(this.booking.sport_man_phone)
      ) {
        this.errors.phone =
          "El teléfono debe ser un número de máximo 9 dígitos";
      } else {
        this.errors.phone = "";
      }
    },
    validateForm() {
      this.validatePhone();
      return this.errors.phone === "";
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    },

    mtdGetHour: function () {
      this.post({
        url: this.$store.getters.get__url + "/bookings/countReserva",
        token: this.$store.getters.get__token,
        params: {
          Campus: this.$store.getters.get_campu.value,
        },
      })
        .then((response) => {
          this.ofCall = response.data;
          this.bringDate();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    bringDate: function () {
      this.ofCall.forEach((item) => {
        let backgroundColor, title;
        switch (item.state) {
          case 1:
            backgroundColor = "#17a2b8";
            title = "Res.";
            break;
          case 2:
            backgroundColor = "#174ab8";
            title = "En C.";
            break;
          case 3:
            backgroundColor = "#1fb112";
            title = "Fin.";
            break;
          default:
            backgroundColor = "gray";
            title = "Desconocido";
            break;
        }
        let localTimeStar = moment(item.start_time, "HH:mm:ss");
        let tranStartDate = localTimeStar.format("[T]HH:mm:ss");

        let localTimeEnd = moment(item.end_time, "HH:mm:ss");
        let tranEndDate = localTimeEnd.format("[T]HH:mm:ss");
        const startDate = item.date.includes("T")
          ? item.date
          : item.date + tranStartDate;
        const endDate = item.date.includes("T")
          ? item.date
          : item.date + tranEndDate;
        const newEvent = {
          title: title,
          start: startDate,
          end: endDate,
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
          textColor: "white",
          extendedProps: {
            state: item.state,
            date: item.date,
            id: item.id,
          },
        };

        this.events.push(newEvent);
      });
    },
    //Cuando haga clic abre el modal del detalle de calendario
    handleEventClick: function (info) {
      const state = info.event.extendedProps.state;
      const date = info.event.extendedProps.date;
      const id = info.event.extendedProps.id;
      const formattedDate = moment(date).format("DD/MM/YYYY");

      let stateText;

      switch (state) {
        case 1:
          stateText = "Reservado";
          break;
        case 2:
          stateText = "En Curso";
          break;
        case 3:
          stateText = "Finalizado";
          break;
        default:
          stateText = "Desconocido";
          break;
      }
      this.get({
        url: this.$store.getters.get__url + "/bookings/detailReserva/" + id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.modal.title = `Detalle de Reservas: Estado ${stateText}, Fecha ${formattedDate}`;
          this.modal.item.push(response.data);
          this.modal.modal_form = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModal: function () {
      this.modal = {
        title: "",
        modal_form: false,
        item: [],
      };
    },
  },
};
</script>

<style scoped>
.bg-purple {
  background-color: #926cdc;
}

.color-white {
  color: white;
}

.bg-gris {
  background-color: #dee2e6;
}

.container-type {
  height: 30px;
}

.text {
  margin: auto;
  font-size: 12px;
}

.fontfamily-sans-serif {
  font-family: sans-serif;
}

.rounded-span {
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: bold;
}
.text-span {
  background-color: #17a2b8;
  color: #dee2e6;
}

.text-span-curso {
  background-color: #174ab8;
  color: #dee2e6;
}
.text-span-ter {
  background-color: #1fb112;
  color: #dee2e6;
}
</style>
