<template>
  <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title"
    :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12">
        <div id="table-detalle">
          <div class="modal-body">
            <div class="modal-body mr-2 ml-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput label="Fecha de Reserva" type="date" v-model="booking.reservation_date"
                    @input="updateReservationDay" />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <cSelectForm label="Campo Deportivo" placeholder="Seleccione campo deportivo"
                    :options="data_sport_field" :value.sync="booking.sport_field_id" :disabled="!this.attention"
                    @change="mtdSelectCampo" />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <cSelectForm label="Deporte" placeholder="Seleccione deporte" :options="data_sport"
                    :value.sync="booking.sport_id" :disabled="!booking.sport_field_id" @change="mtdSelectSport" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <CInput label="Hora de Inicio" type="time" v-model="booking.start_time" :disabled="!booking.sport_id"
                    @blur="determineShift" />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <cSelectForm label="Horas" :placeholder="customHoursPlaceholder" :options="data_hours"
                    :value.sync="booking.hours" :disabled="!booking.start_time" @change="openCustomHourModal" />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <CInput label="Hora Final" type="time" v-model="booking.end_time" disabled />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-3 mb-3">
                  <CInput label="Precio" v-model="booking.total_pay" disabled />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput label="Dni" placeholder="Digite dni" v-model="booking.sport_man_dni" maxlength="8"
                    @keyup="mtdSearchDocument" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput label="Cliente" v-model="booking.name" :disabled="booking.existingSportman" />
                </div>
                <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                  <CInput label="Telefono" v-model="booking.phone" maxlength="9" :disabled="!booking.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button @click="mtdUpdateBooking" class="btn btn-success btn-sm" :disabled="!canSave">
        Actualizar
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";
import cSelectForm from "../../shared/inputs/cSelect.vue";
import moment from "moment";
export default {
  name: "c-updatereserva-modal",
  components: {
    CModal,
    cSelectForm,
  },
  data() {
    return {
      customHoursPlaceholder: "Seleccione cant. de horas",
      swal: null,
      flag_select_campo: 0,
      flag_select_start_time: 0,
      data_sport_field: [],
      data_sport: [],
      data_rates: [],
      booking: {
        id: this.$props.id,
        employee_id: "",
        sport_field_id: "",
        rate_id: "",
        sport_field_name: "",
        sport_id: "",
        sport_name: "",
        sport_fieldxsport_id: "",
        reservation_date: "",
        reservation_day: "",
        start_time: "",
        hours: "",
        end_time: "",
        name: "",
        phone: "",
        subtotal_pay: "",
        total_pay: "",
        sport_man_id: "",
        sport_man_dni: "",
        sport_man_address: "",
        existingSportman: true,
        shift: "",
      },
      schedule: {
        id: "",
        start_time: "",
        end_time: "",
        shift: "",
      },
      attention: false,
      shifts: {
        morning: { start: "00:00", end: "11:59" },
        afternoon: { start: "12:00", end: "17:59" },
        night: { start: "18:00", end: "23:59" },
      },
      data_hours: [
        { value: "00:30", label: "30 min" },
        { value: "01:00", label: "1 Hora" },
        { value: "01:30", label: "1 Hora y 30 min" },
        { value: "02:00", label: "2 Horas" },
        { value: "Otros", label: "Otros" },
      ],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    id: Number,
  },
  created() {
  },
  watch: {
    boo_modal(newVal) {
      if (newVal) {
        this.get_employeeid();
        this.mtd_show();
        this.indexRate();
      }
    },
    bookingTrigger(newVal) {
      if (newVal) {
        this.determineShift();
        this.showPrice();
      }
    },
    "booking.start_time": function (newId) {
      if (newId) {
      }
    },
    "booking.hours": function (newValue) {
      if (newValue === "Otros" || newValue === "") {
        this.openCustomHourModal();
      } else {
        this.calculateEndTime();
      }
    },
  },
  computed: {
    canSave() {
      return (
        this.booking.reservation_date !== "" &&
        this.booking.sport_field_id !== "" &&
        this.booking.sport_id !== "" &&
        this.booking.start_time !== "" &&
        this.booking.hours !== "" &&
        this.booking.end_time !== "" &&
        this.booking.sport_man_id !== ""
      );
    },
    bookingTrigger() {
      return this.booking.sport_field_id + this.booking.sport_id;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    openCustomHourModal() {
      if (this.booking.hours === 'Otros') {
        Swal.fire({
          title: 'Ingrese una hora personalizada (HH:mm)',
          input: 'text',
          inputPlaceholder: 'HH:mm',
          showCancelButton: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            const customHour = result.value;
            if (this.validateCustomHour(customHour)) {
              if (this.isWithinHourLimits(customHour)) {
                this.booking.hours = customHour;
                const [hours, minutes] = customHour.split(":").map(Number);
                this.customHoursPlaceholder = `${hours} horas y ${minutes} min`;
              } else {
                Swal.fire('Hora inválida', 'Por favor, ingrese un valor entre 30 minutos y 6 horas', 'error');
                this.booking.hours = '';
                this.customHoursPlaceholder = "Seleccione cant. de horas";
              }
            } else {
              Swal.fire('Hora inválida', 'Por favor, ingrese una hora en formato HH:mm', 'error');
              this.booking.hours = '';
              this.customHoursPlaceholder = "Seleccione cant. de horas";
            }
          }
        });
      }
    },
    isWithinHourLimits(hourString) {
      const [hours, minutes] = hourString.split(":").map(Number);
      const totalMinutes = hours * 60 + minutes;
      return totalMinutes >= 30 && totalMinutes <= 360;
    },
    validateCustomHour(customHour) {
      const timePattern = /^(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;
      return timePattern.test(customHour);
    },
    mtdClose() {
      ((this.booking = {
        reservation_date: "",
      }),
        (this.flag_select_campo = 0),
        (this.flag_select_start_time = 0)),
        this.$emit("closeModalUpdateBooking");
    },
    formatDate(date) {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatToOriginalDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    indexRate: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/rate/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_rates = [];
          response.data.rates.forEach((element) => {
            this.data_rates.push({
              value: element.id,
              shift: element.shift,
              price: element.price,
              sport_fieldxsport_id: element.sport_fieldsxsport_id,
              detail: element,
            });
          });
          this.bySport_Field();
        })
        .catch((errors) => { });
    },
    bySport_Field: function () {
      this.data_sport_field = [];
      const addedFields = new Set();
      this.data_rates.forEach((arr) => {
        const sportFieldId = arr.detail.sport_fieldxsport.sport_field_id;
        if (!addedFields.has(sportFieldId)) {
          addedFields.add(sportFieldId);
          const { id, name } = arr.detail.sport_fieldxsport.sport_field;
          this.data_sport_field.push({
            label: name,
            value: id,
          });
        }
      });
      this.mtdSelectCampo();
    },
    mtdSelectCampo: function () {
      this.data_sport = [];
      let sport_id = -1;
      if (this.flag_select_campo != 0) {
        this.booking.hours = "";
        this.booking.end_time = "";
      }
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
          this.booking.sport_field_id
        ) {
          if (arr.detail.sport_fieldxsport.sport.id != sport_id) {
            sport_id = arr.detail.sport_fieldxsport.sport.id;
            const { id, name } = arr.detail.sport_fieldxsport.sport;
            this.data_sport.push({
              label: name,
              value: id,
            });
          }
        }
      });
      this.flag_select_campo++;
      this.mtdSelectSport();
    },
    mtdSelectSport: function () {
      this.data_rates.forEach((arr) => {
        if (
          arr.detail.sport_fieldxsport.sport_field_id ==
          this.booking.sport_field_id &&
          arr.detail.sport_fieldxsport.sport_id == this.booking.sport_id
        ) {
          this.booking.sport_fieldxsport_id = arr.detail.sport_fieldxsport.id;
        }
      });
    },
    mtd_show: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/" +
          this.$props.id +
          "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.booking = response;
          this.booking.sport_fieldxsport_id =
            response.rate.sport_fieldsxsport_id;
          this.booking.sport_field_id =
            response.rate.sport_fieldxsport.sport_field_id;
          this.booking.sport_field_name =
            response.rate.sport_fieldxsport.sport_field.name;
          this.booking.sport_id = response.rate.sport_fieldxsport.sport_id;
          this.booking.sport_name = response.rate.sport_fieldxsport.sport.name;
          this.booking.reservation_date = this.booking.reservation_date;
          this.booking.sport_man_id = response.sportsman_id;
          this.booking.existingSportman = true;
          this.booking.sport_man_dni = response.sportsman.dni;
          this.updateReservationDay();
        })
        .catch((errors) => { });
    },
    updateReservationDay() {
      if (this.booking.reservation_date) {
        const date = new Date(this.booking.reservation_date);
        const days = [
          "DOMINGO",
          "LUNES",
          "MARTES",
          "MIERCOLES",
          "JUEVES",
          "VIERNES",
          "SABADO",
        ];
        this.booking.reservation_day = days[date.getUTCDay()];
      }
      this.showTime();
    },
    showTime: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/campu/" +
          this.$store.getters.get_campu.value +
          "/" +
          this.booking.reservation_day + "/" +
          this.booking.reservation_date,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "No hay atención",
              icon: "warning",
            });
            this.attention = false;
          } else {
            this.attention = true;
            response.data.data.forEach((element) => {
              this.schedule.start_time = element.start_time;
              this.schedule.end_time = element.end_time;
            });
          }
        })
        .catch((errors) => { });
    },
    determineShift: function () {
      if (this.flag_select_start_time != 0) {
        this.booking.hours = "";
        this.booking.end_time = "";
      }
      const startTime = moment(this.booking.start_time, "HH:mm");
      const { morning, afternoon, night } = this.shifts;
      if (
        startTime.isBetween(
          moment(morning.start, "HH:mm"),
          moment(morning.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Dia";
        this.booking.shift = "Dia";
      } else if (
        startTime.isBetween(
          moment(afternoon.start, "HH:mm"),
          moment(afternoon.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Tarde";
        this.booking.shift = "Tarde";
      } else if (
        startTime.isBetween(
          moment(night.start, "HH:mm"),
          moment(night.end, "HH:mm"),
          null,
          "[)"
        )
      ) {
        this.schedule.shift = "Noche";
        this.booking.shift = "Noche";
      }
      this.flag_select_start_time++;
      if (this.schedule.start_time) {
        this.validateHoursInicio();
      }
    },
    validateHoursInicio: function () {
      const openingTime = this.formatTime(this.schedule.start_time);
      const closingTime = this.formatTime(this.schedule.end_time);
      const selectedTime = this.formatTime(this.booking.start_time);
      if (openingTime === closingTime) {
        return;
      }
      if (closingTime < openingTime) {
        if (!(selectedTime >= openingTime || selectedTime < closingTime)) {
          Swal.fire({
            title: "La sede abre a las " + openingTime,
            icon: "warning",
          });
          this.booking.start_time = "";
        }
      } else {
        if (selectedTime < openingTime || selectedTime >= closingTime) {
          Swal.fire({
            title: "La sede abre a las " + openingTime,
            icon: "warning",
          });
          this.booking.start_time = "";
        }
      }
    },
    formatTime: function (timeString) {
      const [hours, minutes] = timeString.split(":");
      return `${hours}:${minutes}`;
    },
    calculateEndTime() {
      if (!this.booking.start_time || !this.booking.hours) {
        return;
      }
      const [startHours, startMinutes] = this.booking.start_time.split(":").map(Number);
      const totalStartMinutes = startHours * 60 + startMinutes;
      const [durationHours, durationMinutes] = this.booking.hours.split(":").map(Number);
      const totalDurationMinutes = durationHours * 60 + durationMinutes;
      let totalEndMinutes = totalStartMinutes + totalDurationMinutes;
      totalEndMinutes = totalEndMinutes % 1440;
      const endHours = Math.floor(totalEndMinutes / 60);
      const endMinutes = totalEndMinutes % 60;
      const formattedHours = endHours.toString().padStart(2, "0");
      const formattedMinutes = endMinutes.toString().padStart(2, "0");
      this.booking.end_time = `${formattedHours}:${formattedMinutes}`;
      this.showPrice();
    },
    showPrice: function () {
      let date = this.formatToOriginalDate(this.booking.reservation_date);
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/priceUpdate/" +
          this.booking.id +
          "/" +
          date +
          "/" +
          this.booking.start_time +
          "/" +
          this.booking.end_time +
          "/" +
          this.booking.reservation_day +
          "/" +
          this.booking.shift +
          "/" +
          this.booking.sport_fieldxsport_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Canchita Ocupada",
              text:
                response.data.data.start_time +
                " hasta " +
                response.data.data.end_time,
              icon: "warning",
            });
          } else if (response.data.state == 2) {
            Swal.fire({
              title: "Agregue Tarifa",
              icon: "warning",
            });
          } else {
            response.data.data.forEach((element) => {
              this.booking.rate_id = element.id;
              this.booking.subtotal_pay = element.price;
            });
            let subtotalPay = parseFloat(this.booking.subtotal_pay);
            let hours = this.convertToDecimal(this.booking.hours);
            this.booking.total_pay = (subtotalPay * hours).toFixed(2);
          }
        })
        .catch((errors) => { });
    },
    convertToDecimal: function (time) {
      const [hours, minutes] = time.split(":").map(Number);
      return hours + minutes / 60;
    },
    validateDNI: function (dni) {
      const dniPattern = /^[0-9]{8}$/;
      return dniPattern.test(dni);
    },
    get_employeeid: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/employee/" +
          this.$store.getters.get__user_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.booking.employee_id = element.id;
          });
        })
        .catch((errors) => { });
    },
    filtrarPhone: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/bookings/sportman/" +
          this.booking.sport_man_dni,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            this.booking.phone = "";
            this.booking.sport_man_address = "";
          } else {
            response.data.data.forEach((element) => {
              this.booking.phone = element.phone;
              this.booking.sport_man_id = element.id;
            });
          }
        })
        .catch((errors) => { });
    },
    mtdUpdateBooking() {
      if (
        this.booking.reservation_date &&
        this.booking.employee_id &&
        this.booking.name
      ) {
        this.flag_select_campo = 0;
        this.flag_select_start_time = 0;
        this.post({
          url: this.$store.getters.get__url + "/bookings/update",
          token: this.$store.getters.get__token,
          params: this.booking,
        })
          .then((response) => {
            if (response.data.state == 2) {
              Swal.fire({
                title: "Agregue Tarifa",
                icon: "warning",
              });
            } else {
              let color = "";
              let message = "";
              if (response.statusCode == 200) {
                color = "success";
                message = "REGISTRADO CORRECTAMENTE";
              } else {
                color = "danger";
                message = "error";
              }
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Reserva Editada",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$emit("closeModalUpdateBooking");
              this.resetFormData();
            }
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Complete todos los campos!",
        });
      }
    },
    resetFormData() {
      this.booking = {
        id: "",
        employee_id: "",
        rate_id: "",
        sport_field_id: "",
        sport_field_name: "",
        sport_id: "",
        sport_name: "",
        sport_fieldxsport_id: "",
        reservation_date: "",
        reservation_day: "",
        start_time: "",
        hours: "",
        end_time: "",
        name: "",
        phone: "",
        subtotal_pay: "",
        total_pay: "",
        sport_man_id: "",
        sport_man_name: "",
        sport_man_phone: "",
        sport_man_dni: "",
        sport_man_address: "",
        existingSportman: true,
      };
    },
    mtdSearchDocument: function () {
      if (this.booking.sport_man_dni.length == 8) {
        this.get({
          url:
            this.$store.getters.get__url +
            "/Consultas/" +
            this.booking.sport_man_dni,
          token: this.$store.getters.get__token,
        }).then((response) => {
          if (response.data.boo == 3) {
            Swal.fire({
              title: "DNI no encontrado",
              icon: "warning",
            });
          } else {
            this.booking.name =
              response.data.name + " " + response.data.last_name;
            this.booking.sport_man_address = response.data.direccion;
            this.filtrarPhone();
          }
        });
      }
    },
  },
};
</script>

<style scoped>
  .bg-purple {
    background-color: #926cdc;
  }

  .color-white {
    color: white;
  }
</style>
