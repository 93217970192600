<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
  <div v-if="type == 'ingreso'">
    <div>
      <label>Reserva</label>
      <table class="table table-hover" v-if="type == 'ingreso'">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>Hora</th>
          <th>A nombre de</th>
          <th>Serie</th>
          <th>Número</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(income, index) in incomes_reserva" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ income.voucher.date }}</td>
          <td>{{ new Date(income.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</td>
          <td>{{ income.voucher.voucher_fullname }}</td>
          <td>{{ income.voucher.serie }}</td>
          <td>{{ income.voucher.number }}</td>
          <td>{{ income.amount }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmountReserva() }}</td>
        </tr>
      </tfoot>
    </table>
    </div>
    <div>
      <label>Ventas de Productos</label>
      <table class="table table-hover" v-if="type == 'ingreso'">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>Hora</th>
          <th>A nombre de</th>
          <th>Serie</th>
          <th>Número</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(income, index) in incomes" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ income.voucher.date }}</td>
          <td>{{ new Date(income.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</td>
          <td>{{ income.voucher.voucher_fullname }}</td>
          <td>{{ income.voucher.serie }}</td>
          <td>{{ income.voucher.number }}</td>
          <td>{{ income.amount }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="6" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmount() }}</td>
        </tr>
      </tfoot>
    </table>
    </div>
  </div>
    
    <table class="table table-hover text-table" v-else>
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Serie - Numero</th>
          <th>Motivo</th>
          <th>Devolucion</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(income, index) in incomes" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ income.date }}</td>
          <td>{{ income.voucher_fullname }}</td>
          <td>{{ income.serie }} - {{ income.number }}</td>
          <td>{{ income.cancels[0].motive_cancel }}</td>
          <td>{{ income.amount }}</td>
        </tr>
      </tbody>
    </table>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>
<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";

export default {
  name: "c-income-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
      data_chargue: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    incomes: Array,
    incomes_reserva: Array,
    type: String,
  },
  watch: {
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalIncome");
    },
    totalAmountReserva() {
      return this.incomes_reserva?.reduce((total, income) => {
        return total + parseFloat(income.amount);
      }, 0).toFixed(2); // Asegúrate de mostrar solo dos decimales
    },
    totalAmount() {
      return this.incomes?.reduce((total, income) => {
        return total + parseFloat(income.amount);
      }, 0).toFixed(2); // Asegúrate de mostrar solo dos decimales
    },
  },
};
</script>
<style scoped>
.text-table {
  text-align: center;   /* Centrar horizontalmente */
    vertical-align: middle; /* Centrar verticalmente */

}
</style>
