<template>
  <div class="container mx-auto p-4">
    <CRow>
      <CCol xs="12" sm="12" md="12" lg="4" xl="4">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-store"></i>&nbsp;Stock</span>
            <button
              @click="returnHome"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <input
              type="text"
              placeholder="Buscar"
              class="form-control form-control-sm mb-4"
              v-model="searchQuery"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="12" lg="8" xl="8">
        <CRow>
          <CCol
            v-for="(item, index) in filteredProducts"
            :key="index"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <CCard>
              <CCardHeader
                :class="[
                  getStockColor(item.stock, item.stock_min)[0],
                  getStockColor(item.stock, item.stock_min)[1],
                  'text-center',
                  'font-weigh',
                ]"
              >
                <b>{{ item.label }}</b>
              </CCardHeader>
              <CCardBody>
                <div>
                  <b
                    >Stock:
                    {{ item.stock ? parseInt(item.stock) : item.stock }}
                    {{ item.label_unit }}</b
                  >
                </div>
                <div>
                  <b>Precio: S/. {{ item.price }} </b>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import { CCard, CCardBody, CCardHeader } from "@coreui/vue-pro";
export default {
  name: "stock",
  components: {},
  data() {
    return {
      data_product: [],
      searchQuery: "", // Para la búsqueda
    };
  },
  computed: {
    filteredProducts() {
      return this.data_product.filter((item) => {
        const search = this.searchQuery.toLowerCase();
        return (
          item.label.toLowerCase().includes(search) ||
          item.label_unit.toLowerCase().includes(search) ||
          item.price.toString().includes(search)
        );
      });
    },
  },
  watch: {},
  created() {
    this.showProduct();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome() {
      this.$emit("returnHome");
    },
    showProduct() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/sales/byProduct/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { stock, product_unit } = arr;
            this.data_product.push({
              label: product_unit.product.name,
              value: product_unit.product.id,
              price: product_unit.product.price[0].sale_price,
              value_unit: product_unit.unit.id,
              label_unit: product_unit.unit.name,
              stock: stock,
              stock_min: product_unit.product.stock_min,
            });
          });
        })
        .catch((errors) => {});
    },
    // Función para determinar el color del stock
    getStockColor(stock, stock_min) {
      if (stock_min == null) {
        return ["bg-light", "text-dark"];
      }
      if (stock < stock_min) {
        return ["bg-danger", "text-white"]; // Fondo rojo, texto blanco
      } else if (stock >= stock_min && stock <= stock_min + 15) {
        return ["bg-warning", "text-white"]; // Fondo amarillo, texto oscuro
      } else {
        return ["bg-success", "text-white"]; // Fondo verde, texto blanco
      }
    },
  },
};
</script>
<style scoped>
</style>
