<template>
  <div class="container mx-auto">
    <CRow>
      <CCol xs="12" sm="12" md="4" lg="4" xl="4">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fas fa-store"></i>&nbsp;Compra</span>
            <button
              @click="returnHome"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <cSelectForm
                  label="Proveedores"
                  placeholder="Seleccione proveedor"
                  :options="data_provider"
                  :value.sync="purchase.provider_id"
                />
              </CCol>

              <CCol xs="12" sm="12" md="12" lg="6">
                <CInput
                  label="Fecha de compra"
                  type="date"
                  v-model="fechaCompra"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="6">
                <cSelectForm
                  label="Tipo de Comprobante"
                  placeholder="Seleccione.. "
                  :options="data_document_type"
                  :value.sync="purchase.document_type_id"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="6">
                <CInput
                  label="N° de documento"
                  placeholder="Digite N° documento"
                  v-model="purchase.document"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="6">
                <cSelectForm
                  label="Medio de Pago"
                  placeholder="Seleccione.."
                  :options="data_method_payment"
                  :value.sync="purchase.method_payment_id"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12" sm="12" md="8" lg="8" xl="8">
        <CCard>
          <CCardHeader>
            <span><i class="fas fa-coins"></i>&nbsp;Productos</span>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6">
                <cSelectForm
                  label="Producto"
                  placeholder="Seleccione producto"
                  :options="data_product"
                  :value.sync="newProduct.product.id"
                  @change="saveProduct"
                />
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6">
                <cSelectForm
                  label="Unidad Base"
                  placeholder="Seleccione Unidad Base"
                  :options="data_unit"
                  :value.sync="newProduct.unit.id"
                  @change="saveUnit"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs="12" sm="12" md="3" lg="3">
                <CInput
                  label="Precio"
                  placeholder="Digite precio"
                  v-model="newProduct.price"
                  @keyup="validatePositive('price')"
                  @keypress.enter="mtdNextFocus(1)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="3" lg="3">
                <CInput
                  label="Cantidad"
                  type="number"
                  placeholder="cantidad"
                  v-model="newProduct.quantity"
                  @change="validatePositive('quantity')"
                  ref="inputQuantity"
                  @keypress.enter="mtdNextFocus(2)"
                />
              </CCol>
              <CCol xs="12" sm="12" md="3" lg="3">
                <CInput label="Sub-Total" :value="calculateSubtotal" readonly />
              </CCol>
              <CCol
                xs="12"
                sm="12"
                md="3"
                lg="3"
                class="d-flex align-items-center mt-3"
              >
                <button
                  @click="addProduct"
                  type="button"
                  class="btn btn-sm text-white font-bold"
                  style="background: #57cc99"
                >
                  <i class="fas fa-plus"></i> Agregar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardHeader class="d-flex justify-content-start">
            <span><i class="fa fa-user"></i>&nbsp; Lista de productos </span>
          </CCardHeader>
          <CCardBody>
            <div class="table-responsive">
              <table
                id="dataTableLists"
                class="table table-sm table-striped table-hover table-bordered dt-responsive nowrap"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th width="60">N°</th>
                    <th>Producto</th>
                    <th>Unidad Base</th>
                    <th>Precio</th>
                    <th>Cantidad</th>
                    <th>Subtotal</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, index) in productList" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ product.product.name }}</td>
                    <td>{{ product.unit.name }}</td>
                    <td>{{ product.price }}</td>
                    <td>{{ product.quantity }}</td>
                    <td>{{ (product.price * product.quantity).toFixed(2) }}</td>
                    <td class="text-center">
                      <button
                        @click="removeProduct(index)"
                        class="btn btn-danger btn-sm"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5" class="text-right font-bold">Total</td>
                    <td class="font-bold">{{ calculateTotal.toFixed(2) }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <CRow>
              <CCol
                xs="12"
                sm="12"
                md="12"
                lg="12"
                class="d-flex justify-content-end"
              >
                <button
                  @click="check_action"
                  type="button"
                  class="btn btn-sm text-white"
                  style="background: #39f"
                  :disabled="!cpButton"
                >
                  <i class="fas fa-save"></i> Guardar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import cSelectForm from "../../../shared/inputs/cSelect.vue";
import CTableWrapper from "../../../../components/shared/datatable/Table.vue";
import { bus } from "../../../../main";
import { CCardBody } from "@coreui/vue-pro";

export default {
  name: "purchases",
  components: {
    cSelectForm,
    CTableWrapper,
  },
  data() {
    return {
      prefix: "purchases",
      data_provider: [],
      data_document_type: [],
      data_method_payment: [],
      data_product: [],
      data_unit: [],
      fechaCompra: moment().format("YYYY-MM-DD"),
      purchase: {
        id: "",
        campu_id: this.$store.getters.get_campu.value,
        user_id: this.$store.getters.get__user_id,
        method_payment_id: "",
        document_type_id: "",
        provider_id: "",
        document: "",
        total: "",
        date: "",
      },
      newProduct: {
        product: {
          id: "",
          name: "",
        },
        unit: {
          id: "",
          name: "",
        },
        price: "",
        quantity: 1,
        subtotal: 0,
      },
      productList: [],
    };
  },
  computed: {
    calculateSubtotal() {
      return (this.newProduct.subtotal = (
        this.newProduct.price * this.newProduct.quantity
      ).toFixed(2));
    },
    calculateTotal() {
      return this.productList.reduce((total, product) => {
        return total + product.price * product.quantity;
      }, 0);
    },
    cpButton() {
      return (
        this.purchase.provider_id &&
        this.purchase.method_payment_id &&
        this.purchase.document_type_id &&
        this.purchase.document &&
        this.purchase.total &&
        this.productList.length > 0
      );
    },
  },
  watch: {
    productList: {
      handler() {
        this.purchase.total = this.calculateTotal.toFixed(2);
      },
      deep: true,
    },
  },
  created() {
    this.showProvider();
    this.showDocumentType();
    this.showMethodPayment();
    this.showProduct();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome() {
      this.$emit("returnHome");
    },
    showProvider() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byProvider/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_provider.push({
              label: name,
              value: id,
            });
          });
        })
        .catch((errors) => {});
    },
    showDocumentType() {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/byDocumentType",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_document_type.push({
              label: name,
              value: id,
            });
          });
        })
        .catch((errors) => {});
    },
    showMethodPayment() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byMethodPayment/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_method_payment.push({
              label: name,
              value: id,
            });
          });
        })
        .catch((errors) => {});
    },
    showProduct() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byProduct/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_product.push({
              label: name,
              value: id,
            });
          });
        })
        .catch((errors) => {});
    },
    saveProduct() {
      this.data_unit = [];
      const product_id = this.newProduct.product.id;
      this.data_product.forEach((arr) => {
        if (arr.value == product_id) {
          this.newProduct.product.name = arr.label;
        }
      });

      this.showUnit();
    },
    saveUnit() {
      const unit_id = this.newProduct.unit.id;
      this.data_unit.forEach((arr) => {
        if (arr.value == unit_id) {
          this.newProduct.unit.name = arr.label;
        }
      });
    },
    showUnit() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byUnit/" +
          this.newProduct.product.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_unit.push({
              label: name,
              value: id,
            });
          });
        })
        .catch((errors) => {});
    },
    addProduct() {
      if (
        this.newProduct.product.name &&
        this.newProduct.unit.name &&
        this.newProduct.price > 0
      ) {
        const productToAdd = {
          product: {
            id: this.newProduct.product.id,
            name: this.newProduct.product.name,
          },
          unit: {
            id: this.newProduct.unit.id,
            name: this.newProduct.unit.name,
          },
          price: this.newProduct.price,
          quantity: this.newProduct.quantity,
          subtotal: this.calculateSubtotal,
        };
        this.productList.push(productToAdd);

        this.newProduct.product.name = "";
        this.newProduct.product.id = "";
        this.data_unit = [];
        this.newProduct.unit.id = "";
        this.newProduct.unit.name = "";
        this.newProduct.price = "";
        this.newProduct.quantity = 1;
        this.purchase.total = this.calculateTotal.toFixed(2);
      }
    },
    removeProduct(index) {
      // this.productList.splice(index, 1);
      Swal.fire({
        title: "¿Desea eliminar el producto?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          // Si el usuario confirma, se elimina el producto
          this.productList.splice(index, 1);
          Swal.fire("Eliminado!", "El producto ha sido eliminado.", "success");
        }
      });
    },
    check_action: function () {
      // Verifica si hay un producto seleccionado que no se ha agregado a la lista
      if (
        this.newProduct.product.id ||
        this.newProduct.unit.id ||
        this.newProduct.price > 0
      ) {
        Swal.fire({
          title: "Tienes un producto seleccionado",
          text: "Hay un producto seleccionado que no se ha agregado a la lista. ¿Deseas agregarlo antes de continuar?",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Agregar producto",
          cancelButtonText: "Continuar sin agregar",
        }).then((result) => {
          if (result.dismiss == "close") {
            Swal.close();
          } else if (result.isConfirmed) {
            // Agrega el producto a la lista
            this.addProduct();
          } else {
            // Si decide continuar sin agregar, envía los datos al backend
            this.mtd_action();
          }
        });
      } else {
        // Si no hay productos seleccionados sin agregar, envía los datos al backend
        this.mtd_action();
      }
    },
    mtd_action: function () {
      this.purchase.date = this.fechaCompra;
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/store",
        token: this.$store.getters.get__token,
        params: {
          purchase: this.purchase,
          purchaseDetail: this.productList,
        },
      })
        .then((response) => {
          let color = "";
          let message = "";
          if (response.statusCode == 200) {
            color = "success";
            message = "REGISTRADO CORRECTAMENTE";
          } else {
            color = "danger";
            message = "error";
          }
          this.fechaCompra = moment().format("YYYY-MM-DD");
          this.purchase = {
            id: "",
            campu_id: this.$store.getters.get_campu.value,
            user_id: this.$store.getters.get__user_id,
            provider_id: "",
            date: "",
            document_type_id: "",
            document: "",
            method_payment_id: "",
            total: "",
          };
          this.productList = [];
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    validatePositive(field) {
      let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");
      const decimalCount = sanitizedValue.split(".").length - 1;
      if (decimalCount > 1) {
        sanitizedValue = sanitizedValue.slice(0, -1);
      }
      const decimalIndex = sanitizedValue.indexOf("."); // Limitar a dos decimales
      if (decimalIndex !== -1) {
        const decimalPart = sanitizedValue.substring(decimalIndex + 1);
        sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
      }

      this.newProduct[field] = sanitizedValue;
    },
    mtdNextFocus: function (step) {
      switch (step) {
        case 1:
          this.newProduct.price = parseFloat(this.newProduct.price).toFixed(2);
          const input = this.$refs.inputQuantity;
          input.$el.children[0].focus();
          break;
        case 2:
          this.addProduct();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.bg-purple {
  background-color: #926cdc;
}
.bg-white {
  background-color: white;
}
.color-white {
  color: white;
}
.color-black {
  color: black;
}
.font-bold {
  font-weight: bold;
}
.table-responsive {
  overflow-x: auto;
}
</style>
