<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper :items="data" :fields="fields" hover :striped="true" :border="true" small fixed
          caption="Lista de roles" icon="fas fa-user-tag" btn_name="Rol" @mtdBack="mtdBack" @show_modal="mtd_open_modal"
          @action_edit="mtd_show" @action_delete="mtd_delete" :size="'md'"
          :button_new="this.$store.getters.get__user_id == 1 ? true : false"
          :actions="this.$store.getters.get__user_id == 1 ? true : false" :withActions="'3%'" :buttonEdit="true"
          :buttonDelete="true" />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="modal.title" :button="cp_button"
      :show.sync="modal.modal_form" @mtd_action="mtd_action" :color="'info'" :centered="true">
      <CRow>
        <CCol sm="12">
          <CInput label="Nombre" placeholder="Digite nombre" v-model="rol.name" />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="'Borrar Rol'"
      :show.sync="modal_delete.boo" @mtd_commit="mtd_commit" :centered="true" :item="modal_delete.item" />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
  // {
  //   key: 'show_details',
  //   label: 'Detalle',
  //   _style: 'min-width:1%'
  // },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete },
  data() {
    return {
      prefix: "rol",
      fields,
      data: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      rol: {
        id: "",
        name: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_button: function () {
      if (this.rol.name != "") return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/rol",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (this.$store.getters.get__user_id != 1) {
            this.data = response.data.roles.filter((role) => role.id !== 1 && role.id !== 5);
          } else {
            this.data = response.data.roles;
          }
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          })
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Rol")
        : (this.modal.title = "Editar Rol");
      action == "store"
        ? (this.rol = {
          id: "",
          name: "",
          status: "",
        })
        : (this.rol = this.rol);
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.rol,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: response.message,
              icon: "warning"
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              this.data.push(response[0]);
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              this.data.forEach((element) => {
                if (element.id == response.data.data[0].id) {
                  element.name = response.data.data[0].name;
                }
                message = "EDITADO CORRECTAMENTE";
              });
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            bus.$emit("alert", {
              color: 'info',
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.rol = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => { });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => { });
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn-search {
    color: #fff;
    background-color: #2819ae;
    border-color: #2517a3;
  }
</style>