<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de trabajador por Sede"
          icon="fas fa-coins"
          btn_name="Trabajador por Sede"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Empresa"
            placeholder="Seleccione empresa"
            :options="data_company"
            :value.sync="employeexcampu.company"
            :disabled="this.$store.getters.get__user_id !== 1 || this.modal.action == 'update'"
            @change="indexCampus"
          />
          <cSelectForm
            label="Sede"
            placeholder="Seleccione sede"
            :options="data_campus"
            :value.sync="employeexcampu.campu_id"
          />

          <cSelectForm
            label="Trabajador"
            placeholder="Seleccione trabajador"
            :options="data_employees"
            :value.sync="employeexcampu.employee_id"
          />
        </CCol>
        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="employeexcampu.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Rol'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "campu_name", label: "Sede", _style: "width:20%;" },
  { key: "employee_name", label: "Trabajador", _style: "width:20%;" },
  { key: "employee_rol", label: "Rol", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "employeesxcampus",
      fields,
      data: [],
      data_employees: [],
      data_campus: [],
      data_company: [],

      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      employeexcampu: {
        id: "",
        employee_id: "",
        campu_id: "",
        employee_name: "",
        campu_name: "",
        company:
          this.$store.getters.get__user_id == 1
            ? ""
            : this.$store.getters.get_company.id,
        state: 0,
      },
      modal_delete: {
        boo: false,
        item: [],
      },

      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.employeexcampu.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (this.employeexcampu.name != "" && this.employeexcampu.campu_id)
        return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
    this.indexCompany();
    
  },
  watch: {
    "employeexcampu": function (newVal) {
      if (this.employeexcampu.company) {
        this.indexCampus();
      }
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    indexCampus: function () {
      this.data_campus = [];
      this.data_employees = [];
      this.data_company.forEach((company) => {
        if (this.employeexcampu.company == company.value) {
          company.campu.forEach((element) => {
            this.data_campus.push({
              label: element.name,
              value: element.id,
            });
          });
          company.employee.forEach((element) => {
            this.data_employees.push({
              label: element.name,
              value: element.id,
            });
          });
        }
      });
    },
    indexCompany: function () {
      this.get({
        url: this.$store.getters.get__url + "/employeesxcampus/company",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.data_company.push({
              label: element.reason_social,
              value: element.id,
              employee: element.employee,
              campu: element.campu,
            });
          });
        })
        .catch((errors) => {});
    },
    mtd_getdata: function () {
      let url;
      if (this.$store.getters.get__user_id == 1) {
        url =
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get__user_id +
          "/0";
      } else {
        url =
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get__user_id +
          "/" +
          this.$store.getters.get_campu.value;
      }
      this.get({
        url: url,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.employeesxcampus;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Trabajador por Sede")
        : (this.modal.title = "Editar Trabajador por Sede");
      action == "store"
        ? (this.employeexcampu = {
            id: "",
            employee_id: "",
            campu_id: this.$store.getters.get__user_id == 1
              ? ""
              : this.$store.getters.get_campu.value,
            employee_name: "",
            campu_name: "",
            company: this.$store.getters.get__user_id == 1
              ? ""
              : this.$store.getters.get_company.id,
            state: "",
          })
        : (this.employeexcampu = {...this.employeexcampu});
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.employeexcampu,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Trabajador ya resgistrado",
              icon: "warning",
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedEmployeexCampu = response.data.data;
              let index = this.data.findIndex(
                (employeexcampu) =>
                  employeexcampu.id === updatedEmployeexCampu.id
              );
              if (index !== -1) {
                this.$set(this.data, index, updatedEmployeexCampu);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.employeexcampu = {
              id: "",
              employee_id: "",
              campu_id: "",
              employee_name: "",
              campu_name: "",
              state: "",
            };

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.employeexcampu = {
            id: response.id,
            employee_id: response.employee.id,
            campu_id: response.campu_id,
            company: response.campu.company_id,
            state: response.state,
          };
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          this.mtd_getdata();
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    changeSwitch: function (payload) {
      this.employeexcampu.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>
