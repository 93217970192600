<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div>
      <label>Reserva</label>
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="60">N°</th>
            <th>Fecha</th>
            <th>Documento</th>
            <th>A nombre de</th>
            <th>Método de pago</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(det, index) in detalle" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ det.date }}</td>
            <td>{{ det.voucher_document }}</td>
            <td>{{ det.voucher_fullname }}</td>
            <td>
              {{ det.charge.map((charge) => charge.method).join(", ") }}
            </td>
            <td>{{ det.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>
  <script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";

export default {
  name: "c-detalle-reporte-reserva-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    detalle: Array,
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalDetalleReporte");
    },
  },
};
</script>
  <style scoped>
</style>
  