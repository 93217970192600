<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div>
      <div class="row">
        <div
          v-for="(item, index) in data_comprobante_pago"
          :key="index"
          :class="[
            'col',
            colClass,
            'd-flex',
            'font-bold',
            item.selected ? 'bg-info' : 'bg-gris',
            'container-type',
          ]"
          @click="selectItem(item.value)"
        >
          <div class="text text-center">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <CRow>
        <CCol sm="12" lg="6">
          <CInput
            :label="isFactura ? 'RUC' : 'Dni Cliente'"
            :placeholder="isFactura ? 'Digite RUC' : 'Digite DNI'"
            :maxlength="isFactura ? 11 : 8"
            v-model="sale_new.entity_document"
            @keyup="mtdSearchDocument"
          />
          <div v-if="isFactura && !isRUCValid" class="text-danger">
            El RUC debe contener solo números y hasta 11 dígitos.
          </div>
        </CCol>
        <CCol sm="12" lg="6">
          <CInput
            :label="isFactura ? 'Razón social' : 'Nombre Cliente'"
            :placeholder="isFactura ? 'Digite razón social' : 'nombre'"
            v-model="sale_new.entity_name"
            disabled
          />
        </CCol>
      </CRow>
      <div class="row">
        <div class="col-lg-6 col-12">
          <h6>MÉTODO DE PAGO</h6>
          <table>
            <tbody v-if="data_metodo_pago.length == 0">
              <tr>
                <td>Sin Metodos de Pago (Agregue en Mantenimiento)</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(item, index) in data_metodo_pago" :key="index">
                <td v-if="item.label != 'MIXTO'">{{ item.label }}</td>
                <td v-if="item.label != 'MIXTO'">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="0"
                    v-model="item.amount"
                    @input="updateSelectedPayments(item)"
                    min="0"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-lg-6 col-12">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="mb-3">
                <CInput label="TOT. VENTA" :value="sale.total" readonly />
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="mb-3">
                <CInput label="TOT. PAGAR" :value="sale.total" readonly />
              </div>
            </div>
          </div>

          <div class="mb-3">
            <CInput label="PAGO DEL CLIENTE" :value="pagoCliente" readonly />
          </div>
          <div class="mb-3">
            <CInput label="PENDIENTE" :value="pendiente" readonly />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger">
        <i class="fa fa-times"></i>&nbsp;Cerrar
      </button>
      <button
        @click="mtd_action"
        :disabled="cpButton"
        class="btn btn-info btn-info"
      >
        <i class="fas fa-dollar-sign"></i>Guardar venta
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";
import moment from "moment";
import { bus } from "../../../../main";
import Sale from "../page/Sale.vue";

export default {
  name: "c-sale-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "sales",
      data_comprobante_pago: [],
      data_metodo_pago: [],
      selectedPayments: [],
      document_type_id: "",
      sale_new: {
        existingSportman: true,
        ruc: "",
        entity_document: "",
        entity_name: "",
        entity_address: "",
        pending: "",
      },
      isRUCValid: true, // Variable para el estado de validación
    };
  },
  props: {
    title: String,
    productList: {
      type: Array,
      required: true,
    },
    sale: {
      type: Object,
      required: true,
    },
    boo_modal: Boolean,
  },
  computed: {
    isFactura() {
      return this.sale_new.document_type_id == 2; // Asume que el ID de Factura es 2
    },
    colClass() {
      const itemsCount = this.data_comprobante_pago.length;
      if (itemsCount === 1) return "col-12";
      if (itemsCount === 2) return "col-6";
      if (itemsCount === 3) return "col-4";
      return "col-6";
    },
    cpButton() {
      if (this.sale_new.document_type_id == 3) {
        return (
          !this.sale_new.document_type_id ||
          !this.selectedPayments.length ||
          this.sale_new.pending != 0
        );
      }else{
        return (
          !this.sale_new.entity_document ||
          !this.sale_new.entity_name ||
          !this.sale_new.document_type_id ||
          !this.selectedPayments.length ||
          this.sale_new.pending != 0
        );
      }
    },
    // Suma de los pagos seleccionados
    pagoCliente() {
      return this.selectedPayments
        .reduce((sum, payment) => sum + parseFloat(payment.amount), 0)
        .toFixed(2);
    },
    // Calcular el monto pendiente a pagar
    pendiente() {
      let pendiente = (this.sale.total - this.pagoCliente).toFixed(2);
      this.sale_new.pending = pendiente;
      return pendiente;
    },
  },
  watch: {
    boo_modal(newVal) {
      if (newVal) {
        // Resetear la copia temporal al abrir el modal
        this.sale_new = { ...this.sale };
      }
    },
  },
  created() {
    this.showDocumentType();
    this.showMethodPayment();
    this.filterBox();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      // Reset "Comprobante de Pago" selection
      this.data_comprobante_pago.forEach((item) => {
        item.selected = false;
      });
      this.$emit("closeModalSale");
    },
    showDocumentType() {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/byDocumentType",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_comprobante_pago.push({
              label: name,
              value: id,
              selected: false,
            });
          });
        })
        .catch((errors) => {});
    },
    showMethodPayment() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/byMethodPayment/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((arr) => {
            const { id, name } = arr;
            this.data_metodo_pago.push({
              label: name,
              value: id,
              amount: "",
            });
          });
        })
        .catch((errors) => {});
    },
    selectItem(index) {
      this.sale_new.document_type_id = index;
      this.data_comprobante_pago.forEach((item, idx) => {
        item.selected = idx === index - 1;
      });

      // Resetear los valores según el tipo de comprobante seleccionado
      if (this.isFactura) {
        this.isRUCValid = true; // Reinicia la validación del RUC
      }
      this.sale_new.entity_document = "";
      this.sale_new.entity_name = "";
    },
    filtrarSportMan: function () {
      if (!this.validateDNI(this.sale_new.entity_document)) {
        Swal.fire({
          title: "DNI inválido",
          text: "El DNI debe tener exactamente 8 dígitos y no debe contener letras.",
          icon: "warning",
        });
        return;
      }
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/sportman/" +
          this.sale_new.entity_document,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "No se encontró DNI",
              icon: "warning",
            });
            this.entity_name = "";
            this.sale_new.sportsman_id = "";
            this.sale_new.existingSportman = false;
          } else {
            response.data.data.forEach((element) => {
              this.sale_new.entity_name = element.name;
              this.sale_new.sportsman_id = element.id;
            });
          }
        })
        .catch((errors) => {});
    },
    updateSelectedPayments(item) {
      if (item.amount < 0) {
        item.amount = 0;
        return;
      }
      // Elimina cualquier elemento existente con el mismo ID
      if (item.amount === "" || parseFloat(item.amount) === 0) {
        this.selectedPayments = this.selectedPayments.filter(
          (payment) => payment.id !== item.value
        );
        return;
      }
      let newTotal;
      if (item.amount) {
        // Elimina cualquier elemento existente con el mismo ID
        this.selectedPayments = this.selectedPayments.filter(
          (payment) => payment.id !== item.value
        );

        newTotal =
          this.selectedPayments.reduce(
            (sum, payment) => sum + parseFloat(payment.amount),
            0
          ) + parseFloat(item.amount);
      }
      // Verifica que la suma de selectedPayments no exceda el sale.total
      if (newTotal <= this.sale.total) {
        if (item.amount > 0) {
          this.selectedPayments.push({
            id: item.value,
            label: item.label,
            amount: item.amount,
          });
        }
      } else {
        Swal.fire({
          title: "La suma de los pagos no puede exceder el total de la venta",
          icon: "warning",
        });
        item.amount = this.sale.total - this.pagoCliente; // Limita el valor máximo al restante
        if (item.amount > 0) {
          this.selectedPayments.push({
            id: item.value,
            label: item.label,
            amount: item.amount,
          });
        }
      }
    },
    mtd_action: async function () {
      if(this.sale_new.document_type_id == 3 && this.sale_new.entity_document.length == 0){
        await this.mtdSearchDocument();
      }
      this.sale_new.date = moment().format("YYYY-MM-DD HH:mm:ss");
      this.sale_new.type = 0; //mejor hacerlo en el back
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/store",
        token: this.$store.getters.get__token,
        params: {
          sale: this.sale_new,
          saleDetail: this.productList,
          charge: this.selectedPayments,
        },
      })
        .then((response) => {
          let color = "";
          let message = "";
          if (response.statusCode == 200) {
            color = "success";
            message = "REGISTRADO CORRECTAMENTE";

            if (response.data.document_type_id == 3) {
              this.openTicket(response.data.voucher_id);
            } else {
              let res = response.data.data.sunat;
              window.open(res.json.enlace_del_pdf_ticket, "_blank");
            }
          } else {
            color = "danger";
            message = "error";
          }
          // Reseteamos la data
          this.resetFormData();
          this.selectedPayments = [];
          // Emit event to parent to reset productList and close modal
          this.$emit("save");
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    resetFormData() {
      this.sale_new = {
        existingSportman: true,
        ruc: "",
        document_type_id: "",
        sportsman_id: "",
        date: "",
        type: 0,
        total: "",
        amount: "",
        pending: "",
        entity_document: "",
        entity_name: "",
        entity_address: "",
        box_id: "",
        bead_id: "",
      };
      this.selectedPayments = [];

      // Reset "Comprobante de Pago" selection
      this.data_comprobante_pago.forEach((item) => {
        item.selected = false;
      });

      // Clear all "Método de Pago" fields
      this.data_metodo_pago.forEach((item) => {
        item.amount = "";
      });
    },
    filterBox() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/filterBox/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          let box = response.data.data;
          this.sale_new.box_id = box.id;
        })
        .catch((erros) => {});
    },
    validateDNI: function (dni) {
      const dniPattern = /^[0-9]{8}$/;
      return dniPattern.test(dni);
    },
    validateRUC: function () {
      let ruc = event.target.value.replace(/[^0-9.]/g, "");
      this.sale_new.entity_document = ruc;
    },
    handleEnterKey() {
      if (!this.isFactura) {
        this.filtrarSportMan();
      }
    },
    handleKeyUp() {
      if (this.isFactura) {
        this.validateRUC();
      }
    },
    openTicket(id) {
      window.open(
        this.$store.getters.get__url +
          "/sales/ticket/" +
          id +
          "/" +
          this.$store.getters.get__user_id,
        "_blank"
      );
    },
    mtdSearchDocument: async function () {
      if (this.sale_new.document_type_id == 1) {
        if (this.sale_new.entity_document.length == 8) {
          await this.get({
            url:
              this.$store.getters.get__url +
              "/Consultas/" +
              this.sale_new.entity_document,
            token: this.$store.getters.get__token,
          }).then((response) => {
            if (response.data.boo == 3) {
              this.sale_new.entity_document = "";
              this.sale_new.entity_name = "";
              this.sale_new.entity_address = "";

              Swal.fire({
                title: "DNI no encontrado",
                icon: "warning",
              });
            } else {
              this.sale_new.entity_name =
                response.data.name + " " + response.data.last_name;
              this.sale_new.entity_address = response.data.direccion;
            }
          });
        }
      } else if (this.sale_new.document_type_id == 2) {
        if (this.sale_new.entity_document.length == 11) {
          await this.get({
            url:
              this.$store.getters.get__url +
              "/Consultas/" +
              this.sale_new.entity_document,
            token: this.$store.getters.get__token,
          }).then((response) => {
            if (response.data.boo == 3) {
              this.sale_new.entity_document = "";
              this.sale_new.entity_name = "";
              this.sale_new.entity_address = "";
              Swal.fire({
                title: "RUC no encontrado",
                icon: "warning",
              });
            } else {
              this.sale_new.entity_name = response.data.razon_social;
              this.sale_new.entity_address = response.data.direccion;
            }
          });
        }
      } else if (this.sale_new.document_type_id == 3) {
        if (this.sale_new.entity_document.length == 8 || this.sale_new.entity_document.length == 0 ) {
          if(this.sale_new.entity_document.length == 0){
            this.sale_new.entity_document = '11111111'
          }
          await this.get({
            url:
              this.$store.getters.get__url +
              "/Consultas/" +
              this.sale_new.entity_document,
            token: this.$store.getters.get__token,
          }).then((response) => {
            if (response.data.boo == 3) {
              this.sale_new.entity_document = "";
              this.sale_new.entity_name = "";
              this.sale_new.entity_address = "";
              Swal.fire({
                title: "DNI no encontrado",
                icon: "warning",
              });
            } else {
              this.sale_new.entity_name =
                response.data.name + " " + response.data.last_name;
              this.sale_new.entity_address = response.data.direccion;
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.bg-purple {
  background-color: #926cdc;
}
.bg-white {
  background-color: white;
}
.bg-gris {
  background-color: #dee2e6;
}
.color-white {
  color: white;
}
.color-black {
  color: black;
}
.font-bold {
  font-weight: bold;
}
.container-type {
  height: 40px;
}
.container-type:hover {
  background-color: #17a2b8; /* bg-info */
  cursor: pointer;
}
.text {
  margin: auto;
}
</style>
