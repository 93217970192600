<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <!-- Sección de Reservas -->
    <div>
      <label>Reserva</label>
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="60">N°</th>
            <th>Fecha</th>
            <th>A nombre de</th>
            <th>Serie</th>
            <th>Número</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data_reserva" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.voucher.date }}</td>
            <td>{{ item.voucher.voucher_fullname }}</td>
            <td>{{ item.voucher.serie }}</td>
            <td>{{ item.voucher.number }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" class="text-right font-weight-bold">Total:</td>
            <td class="font-weight-bold">{{ totalAmountReserva() }}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <!-- Sección de Ventas de Productos -->
    <div>
      <label>Ventas de Productos</label>
      <table class="table table-hover">
        <thead>
          <tr>
            <th width="60">N°</th>
            <th>Fecha</th>
            <th>A nombre de</th>
            <th>Serie</th>
            <th>Número</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.voucher.date }}</td>
            <td>{{ item.voucher.voucher_fullname }}</td>
            <td>{{ item.voucher.serie }}</td>
            <td>{{ item.voucher.number }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" class="text-right font-weight-bold">Total:</td>
            <td class="font-weight-bold">{{ totalAmount() }}</td>
          </tr>
        </tfoot>
      </table>
    </div>

    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times"></i>&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/cModalFormCom.vue";

export default {
  name: "cModalGeneral",
  components: {
    CModal,
  },
  props: {
    title: String,
    boo_modal: Boolean,
    data: Array,           // Lista de ventas de productos
    data_reserva: Array,   // Lista de reservas
  },
  methods: {
    mtdClose() {
      this.$emit("closeModal");
    },
    totalAmountReserva() {
      return this.data_reserva?.reduce((total, item) => {
        return total + parseFloat(item.amount);
      }, 0).toFixed(2);
    },
    totalAmount() {
      return this.data?.reduce((total, item) => {
        return total + parseFloat(item.amount);
      }, 0).toFixed(2);
    },
  },
};
</script>

<style scoped>
</style>
