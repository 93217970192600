<template>
  <div>
    <div v-if="$store.getters.get_all_user.id !== 1">
      <div class="" v-if="$store.getters.get_campu == null || $store.getters.get_rol == null">
        <CRow v-if="$store.getters.get_campu == null">
          <CampusVue :campus="cpCam" @mtdSetCampu="mtdSetCampu" />
        </CRow>
        <CRow v-else>
          <RolVue :roles="roles" @mtdSetRol="mtdSetRol" />
        </CRow>
      </div>
      <div v-else>
        <AdminCompanyVue v-if="$store.getters.get_roles[0].role.id == 2" />
        <ReceptionVue v-if="$store.getters.get_roles[0].role.id == 4" />
      </div>
    </div>
    <div v-else>
      <div v-if="$store.getters.get_rol == null">
        <RolVue :roles="roles" @mtdSetRol="mtdSetRol" />
      </div>
      <div v-else>
        <AdministradorVue />
      </div>
    </div>
  </div>
</template>
<script>
import CampusVue from '../components/home/Campus.vue';
import RolVue from '../components/home/Rol.vue';
import CompanyVue from '../components/home/Companies.vue';
import AdministradorVue from './administrador/AdministradorView.vue';
import ReceptionVue from './reception/ReceptionView.vue';
import AdminCompanyVue from './adminCompany/AdminCompanyView.vue';
import { mapActions } from "vuex";



export default {
  name: 'Dashboard',
  components: { CampusVue, RolVue, AdministradorVue, ReceptionVue, CompanyVue, AdminCompanyVue },
  data() {
    return {
      roles: [],
      rol: null,
      campus: [],
      data_company: [],
    }
  },
  computed: {
    cpCam() {
      return this.$store.getters.get_campus;
    },
    cpCompanies() {
      return this.$store.getters.get_companies;
    },
    cpCompany() {
      return this.$store.getters.get_company;
    },
  },
  created() {
    if (this.$store.state.user.id !== 1) {
      this.captureCompany();
    };
    this.$store.getters.get_all_user.roles.forEach(element => {
      this.roles.push(element.role);
    });
    // this.test();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdSetRol: function (payload) {
      this.$store.commit("mt_set_rol", payload);
    },
    mtdSetCampu: function (payload) {
      this.$store.commit("mt_set_campu", payload);
    },
    mtdSetCompany: function (data) {
      this.$store.commit("mt_set_company", data);
    },
    mtdBackCampus: function () {
      this.$store.commit("mt_set_campu", null);
    },
    captureCompany: function () {
      if (this.$store.getters.get_all_user.id) {

        this.get({
          url: this.$store.getters.get__url + "/campus/company_user/" + this.$store.getters.get_all_user.id,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.$store.commit('mt_set_company', response.data.data);
          })
          .catch((errors) => { });
      }
    },
  }
}
</script>