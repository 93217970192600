<template>
    <div>
      <div>
        <HomeVue v-if="view == 'home'" @mtdBackCampus="mtdBackCampus" @setView="setView"/>
        <CloseBeadVue v-if="view == 'close_bead'" @setView="setView"/>
        <ReservaVue v-if="view == 'reserva'" @mtdViewFooter="mtdViewFooter" @setView="setView"/>
        <VistaNewVue v-if="view == 'vista_new'"  @mtdViewFooter="mtdViewFooter" @setView="setView"/>
        <ProccessVue v-if="view == 'proccess'"  @mtdViewFooter="mtdViewFooter" @setView="setView"/>
  
      </div>
        <FooterVue v-if="!(view == 'reserva') && !(view == 'vista_new') && !(view == 'close_bead')" :view="view" @setView="setView" :booFooter="booFooter" />
    </div>
  </template>
  <script>
  import FooterVue from '../../components/receptionist/Footer.vue';
  import HomeVue from '../../components/receptionist/Home.vue';
  import CloseBeadVue from '../../components/caja/page/CloseBead.vue';
  import ReservaVue from '../../components/receptionist/page/Booking.vue';
  import VistaNewVue from '../../components/receptionist/page/VistaNew.vue';
  import ProccessVue from '../proccess/ProccessView.vue'
  
  export default {
    components: { 
      FooterVue, 
      HomeVue, 
      CloseBeadVue,
      ReservaVue, 
      VistaNewVue,
      ProccessVue
    },
    data() {
      return {
        view: "home",
        booFooter:true
      };
    },
    created(){
    },
    methods: {
      mtdBackCampus: function(){
        this.$emit('mtdBackCampus');
      },
      setView: function (payload) {
        this.view = payload;
      },
      mtdViewFooter: function(boo,view){
        this.booFooter = boo;
        if(boo == true) this.view = view;
      }
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  