<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'md'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <CRow>
      <CCol sm="12">
        <div class="input-group">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="radio"
                v-model="movement_bead.type"
                value="1"
                aria-label="Depósito"
              />
            </div>
          </div>
          <input type="text" class="form-control" value="Deposito" disabled />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input
                type="radio"
                v-model="movement_bead.type"
                value="2"
                aria-label="Retiro"
              />
            </div>
          </div>
          <input type="text" class="form-control" value="Retiro" disabled />
        </div>
        <CInput
          label="Monto"
          type="number"
          :min="0"
          step="0.1"
          v-model="movement_bead.amount"
          @input="validationNumber()"
        />
        <div>
          <label>Descripción</label>
          <textarea
            class="form-control"
            aria-label="With textarea"
            v-model="movement_bead.description"
          ></textarea>
        </div>
      </CCol>
    </CRow>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button
        @click="mtd_action"
        :disabled="cpButton"
        class="btn btn-info btn-sm"
      >
        Guardado
      </button>
    </template>
  </CModal>
</template>
<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";
import { bus } from "../../../main";

export default {
  name: "c-retirement-deposit-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
      movement_bead: {
        id: "",
        bead_id: "",
        campu_id: this.$store.getters.get_campu.value,
        user_id: this.$store.getters.get_all_user.id,
        date: "",
        type: "",
        amount: 0.0,
        description: "",
      },
      bead_final_copy: "",
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    bead_id: [String, Number],
    bead_final: [String, Number],
  },
  watch: {
    bead_final(newVal) {
      this.bead_final_copy = newVal;
    },
  },
  computed: {
    cpButton() {
      return (
        !this.movement_bead.type ||
        !this.movement_bead.amount ||
        !this.movement_bead.description
      );
    },
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.resetFormData();
      this.$emit("closeModalRetirementDeposit");
    },
    resetFormData() {
      this.movement_bead = {
        id: "",
        bead_id: "",
        campu_id: this.$store.getters.get_campu.value,
        user_id: this.$store.getters.get_all_user.id,
        date: "",
        type: "",
        description: "",
        amount: 0.0,
      };
    },
    mtd_action() {
      const currentDate = new Date().toISOString().split("T")[0];
      this.movement_bead.bead_id = this.$props.bead_id;
      this.movement_bead.date = currentDate;
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/movement",
        token: this.$store.getters.get__token,
        params: this.movement_bead,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            let color = "success";
            let message = "REGISTRADO CORRECTAMENTE";
            // Reseteamos la data
            this.resetFormData();
            this.$emit("save");
            bus.$emit("alert", {
              color: color,
              message: message,
            });
            this.$emit("closeModalRetirementDeposit");
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    validationNumber() {
      let value = event.target.value;

      if (value == "" || isNaN(value)) {
        this.movement_bead.amount = "";
        return;
      }
      // Prevenir valores negativos
      if (parseFloat(value) < 0) {
        value = Math.abs(value); // Convertimos cualquier valor negativo a positivo
      }

      // Permitir valores válidos con hasta 2 decimales
      const regex = /^\d+(\.\d{0,2})?$/;

      // Si el valor actual no cumple con el formato o tiene más de 2 decimales, lo corregimos
      if (!regex.test(value)) {
        // Si el valor tiene más de 2 decimales, ajustamos el número
        value = parseFloat(value).toFixed(2);
      }

      // Si ha seleccionado "Retiro" (type === 2) y el monto es mayor a bead_final_copy
      if (
        this.movement_bead.type === "2" &&
        parseFloat(value) > parseFloat(this.bead_final_copy)
      ) {
        bus.$emit("alert", {
          color: "danger",
          message: `El monto no puede ser mayor a ${this.bead_final_copy}`,
        });
        this.movement_bead.amount = this.bead_final_copy; // Limitar al máximo permitido
      } else {
        this.movement_bead.amount = value; // Actualizamos solo el modelo
      }
    },
  },
};
</script>
<style scoped>
</style>
