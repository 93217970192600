<template>
  <div>
    <CRow class="justify-content-center">
      <CCol xs="12" sm="4">
        <CCard class="mx-auto">
          <CCardHeader class="d-flex justify-content-between">
            <b><i class="fas fa-calendar"></i>&nbsp;REPORTE</b>
            <button type="button" class="btn btn-sm text-white" @click="returnHome" style="background: #926cdc">
              <i class="fas fa-home-lg-alt"></i>&nbsp; Inicio
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="6" lg="6" xxl="6">
                <label for="month">Mes</label>
                <select v-model="filter.selectedMonth" @change="mtdGetData" id="month" class="form-control">
                  <option v-for="(month, index) in months" :key="index" :value="index + 1">
                    {{ month }}
                  </option>
                </select>
              </CCol>
              <CCol xs="12" sm="12" md="6" lg="6" xxl="6">
                <label for="year">Año</label>
                <select v-model="filter.selectedYear" @change="mtdGetData" id="year" class="form-control">
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </CCol>
            </CRow>
            <CRow class="d-flex justify-content-end mt-1">
              <CCol xs="12" sm="12" md="12" lg="12" xxl="12" class="d-flex justify-content-end">
                <button type="button" class="btn btn-sm text-white" @click="exportPdf" style="background: #926cdc">
                  <i class="fas fa-file-pdf"></i> Exportar Pdf
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="justify-content-center">
      <CCol xs="12" sm="12" md="12" lg="12" xxl="12">
        <CCard>
          <CCardBody class="mt-2">
            <div id="content-timelinetable">
              <table id="tb-cxc" class="table table-bordered border-main align-middle table-responsive">
                <thead>
                  <tr class="text-center">
                    <th>#</th>
                    <th v-for="(day, index) in dataDiario" :key="index">
                      {{ day.inicial }}<br />
                      {{ day.dia }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="rowIndex in maxRows" :key="rowIndex" class="text-center">
                    <th>{{ rowIndex }}</th>
                    <td v-for="(day, colIndex) in dataDiario" :key="colIndex" :style="getColumnStyle(colIndex)">
                      <div v-if="day.detalles[rowIndex - 1]" @click="mtdOpenModalDetalleReporte(day.detalles[rowIndex - 1])">
                        S/{{ day.detalles[rowIndex - 1].amount }}
                      </div>
                      <div v-else> </div>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr class="text-center">
                    <td><strong>S/ TOTAL DIARIO</strong></td>
                    <td v-for="(day, index) in dataDiario" :key="index" :style="getColumnStyle(index)">
                      <strong>{{ day.total }}</strong>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr class="text-center">
                    <td><strong>S/ TOTAL SEM.</strong></td>
                    <td v-for="(week, index) in dataSem" :key="index" :colspan="week.cols"
                      :style="getWeekTotalStyle(index)">
                      <strong>{{ week.initial ? week.initial : 0 }}</strong>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td><strong>S/ TOTAL MEN.</strong></td>
                    <td :colspan="dataDiario.length"><strong>{{ data.total }}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <div class="container mx-auto p-4">
      <CModalDetalleReporte
        :title="modalDetalleReporte.title"
        :boo_modal="modalDetalleReporte.modal_form"
        :detalle="modalDetalleReporte.detalle"
        @closeModalDetalleReporte="closeModalDetalleReporte"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import CModalDetalleReporte from "./modals/cModalDetalleReporte.vue";

export default {
  name: "reporteMes",
  components: {
    CModalDetalleReporte
  },
  data() {
    return {
      filter: {
        selectedMonth: moment().month() + 1,
        selectedYear: moment().year(),
        campu: this.$store.getters.get_campu.value,
      },
      data: [],
      dataDiario: [],
      dataSem: [],
      showTable: false,
      maxRows: 0,
      months: [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
      ],
      selectedMonth: moment().month() + 1,
      selectedYear: moment().year(),
      years: [moment().year(), moment().subtract(1, 'year').year()],
      modalDetalleReporte:{
        title: "Detalle",
        modal_form: false,
        detalle: [],
      },
    };
  },
  computed: {
    filteredMonths() {
      const currentMonth = moment().month() + 1;
      return this.months.slice(0, currentMonth);
    }
  },
  watch: {},
  created() {
    moment.locale("es");
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome() {
      this.$emit("setView", "home");
    },
    mtdOpenModalDetalleReporte(detalle) {
      this.modalDetalleReporte = {
        title: "Detalle",
        modal_form: true,
        detalle: detalle.voucher,
      };
    },
    closeModalDetalleReporte() {
      this.modalDetalleReporte = {
        modal_form: false,
      };
    },
    mtdGetData: function () {
      this.post({
        url: this.$store.getters.get__url + "/beads/reportReserva",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          this.data = response.data.mensual;
          this.dataDiario = response.data.diario;
          this.dataSem = response.data.sem;
          this.maxRows = this.calculateMaxRows();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    calculateMaxRows() {
      let maxDetails = 0;
      this.dataDiario.forEach(day => {
        if (day.detalles && day.detalles.length > maxDetails) {
          maxDetails = day.detalles.length;
        }
      });
      return maxDetails;
    },

    getColumnStyle(index) {
      const colors = ['#060f7780', '#CCFFCC'];
      const startOfMonth = moment(`${this.filter.selectedYear}-${this.filter.selectedMonth}-01`);
      const startDay = startOfMonth.day();
      const adjustedStartDay = (startDay === 0) ? 6 : startDay - 1;
      const weekIndex = Math.floor((index + adjustedStartDay) / 7);
      return {
        backgroundColor: colors[weekIndex % colors.length],
      };
    },

    getWeekTotalStyle(index) {
      const colors = ['#060f7780', '#CCFFCC'];
      return {
        backgroundColor: colors[index % colors.length],
      };
    },

    exportPdf: function () {
      window.open(
        this.$store.getters.get__url +
        "/beads/viewpdf/" +
        this.$store.getters.get_campu.value + "/" +
        this.filter.selectedMonth + "/" +
        this.filter.selectedYear,
        "_blank"
      );
    },

  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatDateTime(dateTime) {
      return dateTime != null
        ? moment(dateTime).format("DD/MM/YYYY HH:mm")
        : "-";
    },
  },
};
</script>
<style scoped>

  .custom-table th,
  .custom-table td {
    font-size: 11px;
  }

  .border-black {
    border: 1.5px solid black;
  }

  .bg-white {
    background: #eaf4f4 !important;
  }

  .table-responsive {
    overflow-x: auto;
  }
</style>
